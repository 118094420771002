<template>
  <div>
    <section class="bg6 p-t-10 p-b-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
            <sidebarPage />
          </div>

          <div class="col-lg-10 col-xl-9 m-b-25 mt-4">
            <div class="bg4 m-b-10" style="margin-top: 25px">
              <div
                v-for="(address, index) in addressList"
                :key="index"
                class="mt-3 card m-b-5"
                style="border-radius: 15px"
              >
                <div class="card-body">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      align-self: stretch;
                    "
                  >
                    <div
                      style="
                        display: inline-block;
                        background-color: #ffebc1;
                        color: #bb891d;
                        display: flex;
                        padding: 8px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        border-radius: 10px;
                        font-size: 14px;
                        font-weight: 400;
                      "
                    >
                      <button
                        :class="{
                          'star-button-active': address.status === 0,
                          'star-button-inactive': address.status === 1,
                        }"
                        @click="() => editAlamatFavorite(address.address_id)"
                      >
                        <span
                          style="
                            color: var(--neutral-01, #fefefe);
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 13.2px; /* 110% */
                            letter-spacing: -0.12px;
                          "
                        >
                          <i class="fa fa-star" aria-hidden="true"></i>
                        </span>
                      </button>
                      <span></span> Alamat Pengiriman
                    </div>

                    <div>
                      <button
                        class="mr-2"
                        style="
                          width: 50px;
                          padding: 10px;
                          justify-content: center;
                          align-items: center;
                          gap: 10px;
                          border-radius: 30px;
                          background: var(--primary-05, #244786);
                        "
                        @click="editAddress(address)"
                      >
                        <span
                          style="
                            color: var(--neutral-01, #fefefe);
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 13.2px; /* 110% */
                            letter-spacing: -0.12px;
                          "
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                      </button>
                      <button
                        @click="deleteAddress(address.address_id)"
                        class="mr-3"
                        style="
                          width: 50px;
                          padding: 10px;
                          justify-content: center;
                          align-items: center;
                          gap: 10px;
                          border-radius: 30px;
                          background: #ff0000;
                        "
                      >
                        <span
                          style="
                            color: var(--neutral-01, #fefefe);
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 13.2px; /* 110% */
                            letter-spacing: -0.12px;
                          "
                          ><i class="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <!-- ... other HTML content ... -->

                  <div class="mr-3 mt-3">
                    <p class="title-name text-left">
                      {{ address.firstname }}
                      {{ address.lastname }}
                    </p>
                    <p class="text-number text-left">{{ address.telephone }}</p>
                    <p class="text-alamat text-left">
                      {{ address.address_2 }}, {{ address.city }},
                      {{ address.province }}, {{ address.postcode }}
                    </p>
                  </div>
                </div>
              </div>

              <br />

              <router-link
                :to="'/Users/tambahalamat'"
                class="btn-tambah"
                role="button"
                ><span
                  style="
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px; /* 112.5% */
                    letter-spacing: -0.16px;
                  "
                  ><i class="fa fa-plus"></i> Tambah Alamat Pengiriman
                  Lain</span
                ></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "alamatPage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      formData: {
        firstname: "",
        lastname: "",
        telephone: "",
        selectedProvinsi: "",
        selectedKota: "",
        selectedKecamatan: "",
        postcode: "",
        address_2: "",
      },
      addressList: [], // To store the fetched list of addresses
    };
  },

  async mounted() {
    // Fetch the list of addresses from the API
    await this.fetchAddresses();
  },

  methods: {
    async editAlamatFavorite(address_id) {
      const idCustomer = localStorage.getItem("id_customer");
      const token = localStorage.getItem("token");

      const apiUrl =
        "https://stagingapi.eurekabookhouse.com/shipping/edit/alamatFavorite";
      const addressId = address_id;

      const payload = {
        customer_id: idCustomer,
        address_id: addressId,
        status: true,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Show success message using SweetAlert2
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "success",
          title: "Berhasil",
          text: "Alamat telah dijadikan favorit!",
        }).then(() => {
          // Reload the page after the SweetAlert is closed
          window.location.reload();
        });
      } catch (error) {
        // Handle errors
        console.error("Error:", error);

        // Show error message using SweetAlert2
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Error",
          text: "Gagal menjadikan alamat sebagai favorit.",
        });
      }
    },

    async deleteAddress(addressId) {
      // Modify the function to accept addressId as an argument
      try {
        const token = localStorage.getItem("token");
        const idCustomer = localStorage.getItem("id_customer");

        if (!addressId) {
          console.error("No address_id selected.");
          return;
        }

        // Show confirmation dialog using SweetAlert2
        const confirmation = await Swal.fire({
          title: "Apakah Anda yakin?",
          text: "Apakah Anda ingin menghapus alamat ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Hapus",
          confirmButtonColor: "#3860A8",
          cancelButtonText: "Tidak",
          reverseButtons: true, // Set reverseButtons to true for better UX
        });

        // If user confirms deletion
        if (confirmation.isConfirmed) {
          const deleteData = {
            customer_id: idCustomer,
            address_id: addressId,
          };

          const response = await fetch(
            "https://stagingapi.eurekabookhouse.com/shipping/delete/alamat",
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(deleteData),
            }
          );

          if (response.ok) {
            // Address deleted successfully
            await Swal.fire({
              icon: "success",
              title: "Alamat berhasil dihapus!",
              showConfirmButton: true,
            }).then(() => {
              // Refresh the page after the user clicks "OK"
              location.reload();
            });
          } else {
            // Handle the error or display an error message
            const result = await response.json();
            await Swal.fire({
              icon: "error",
              title: "Error",
              text: "Error deleting address: " + result.message,
            });
          }
        }
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error deleting address: " + error,
        });
      }
    },
    editAddress(address) {
      // Populate the form data with the values from the selected address
      this.formData.firstname = address.firstname;
      this.formData.lastname = address.lastname;
      this.formData.telephone = address.telephone;
      this.formData.selectedProvinsi = address.province;
      this.formData.selectedKota = address.city;
      this.formData.selectedKecamatan = address.zone_1;
      this.formData.postcode = address.postcode;
      this.formData.address_2 = address.address_2;

      // You can also save the address_id for later use if needed
      this.selectedAddressId = address.address_id;
      localStorage.setItem("selectedAddressId", address.address_id);

      // Redirect to the "/Users/editalamat" page
      // In the previous page's component
      this.$router.push({
        name: "editalamat",
        params: { formData: this.formData },
      });
    },
    async fetchAddresses() {
      try {
        const token = localStorage.getItem("token");
        const idCustomer = localStorage.getItem("id_customer");

        const response = await fetch(
          `https://stagingapi.eurekabookhouse.com/shipping/alamat?customer_id=${idCustomer}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        // Check if the API request was successful (you may need to adjust this based on your API response)
        if (response.ok) {
          // Update addressList with the fetched data
          this.addressList = result.data;
        } else {
          console.error("Error fetching addresses:", result.status.message);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    },
  },
};
</script>
<style>
.title-name {
  color: var(--neutral-07, #191717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-number {
  color: var(--neutral-06, #4c4949);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  letter-spacing: -0.16px;
}
.text-alamat {
  color: var(--neutral-06, #4c4949);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  align-self: stretch;
}
.btn-tambah {
  display: flex;
  width: 100%;
  color: black;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: var(--secondary-05, #ffc341);
}
.star-button-active {
  width: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(--primary-05, #244786);
}

.star-button-inactive {
  width: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #ffc341;
}
</style>
