import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=5ae9ab7a&scoped=true"
import script from "./MegaMenu.vue?vue&type=script&setup=true&lang=js"
export * from "./MegaMenu.vue?vue&type=script&setup=true&lang=js"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=5ae9ab7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ae9ab7a",
  null
  
)

export default component.exports