<template>
  <div>
    <section style="background-color: #f2f2f2">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-12">
            <sidebarPage />
          </div>
          <div class="col-md-9 col-sm-12">
            <div
              class="mt-5"
              style="
                display: flex;
                width: 100%;
                padding: 15px 10px;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                border-radius: 15px;
                background: var(--neutral-01, #fefefe);
              "
            >
              <span
                style="
                  color: var(--primary-04, #3860a8);
                  text-align: right;

                  /* Style/Title 2 */
                  font-family: Montserrat;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px; /* 112.5% */
                  letter-spacing: -0.16px;
                "
                >Dashboard Akun Anda</span
              >

              <div class="mt-4 row">
                <div
                  class="col mr-5"
                  v-for="(orderStatus, index) in orderStatuses"
                  :key="index"
                  @click="redirectToHistory"
                >
                  <div>
                    <div class="position-relative">
                      <p class="mt-2 text-pesanan">
                        <span class="badge badge-primary">{{
                          orderStatus.count
                        }}</span>
                      </p>
                      <img
                        :src="orderStatus.image"
                        :alt="orderStatus.name"
                        style="
                          width: 32px;
                          height: 32px;
                          flex-shrink: 0;
                          margin-top: -7px;
                        "
                      />
                    </div>
                    <p class="mt-2">{{ orderStatus.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 row" style="padding: 0px">
              <div class="col-md-6 col-sm-12">
                <div v-if="customer">
                  <div
                    style="
                      display: flex;
                      width: 100%;
                      height: 285px;
                      padding: 15px;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 17px;
                      border-radius: 15px;
                      background: var(--neutral-01, #fefefe);
                    "
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                      "
                    >
                      <div
                        style="
                          color: #000;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 18px; /* 112.5% */
                          letter-spacing: -0.16px;
                        "
                      >
                        Akun
                      </div>
                      <div>
                        <router-link :to="'/Users/editProfile'">
                          <button
                            style="
                              display: flex;
                              width: 100px;
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              border-radius: 30px;
                              background: var(--primary-05, #244786);
                            "
                          >
                            <span
                              style="
                                color: var(--neutral-01, #fefefe);
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px; /* 110% */
                                letter-spacing: -0.12px;
                              "
                              >Edit Profile</span
                            >
                          </button>
                        </router-link>
                      </div>
                    </div>

                    <span
                      style="
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15.4px;
                        letter-spacing: -0.14px;
                      "
                    >
                      {{ customer.firstname }} {{ customer.lastname }}</span
                    >
                    <span
                      style="
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15.4px;
                        letter-spacing: -0.14px;
                        text-align: left;
                      "
                    >
                      {{ customer.email }}
                    </span>
                    <span
                      style="
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 15.4px;
                        letter-spacing: -0.14px;
                        text-align: left;
                      "
                    >
                      {{ customer.telephone }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <p>No customer details found</p>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div v-for="(address, index) in addresses" :key="index">
                  <div v-if="address.status === 1">
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        height: 285px;
                        padding: 15px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 17px;
                        border-radius: 15px;
                        background: var(--neutral-01, #fefefe);
                      "
                    >
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          align-self: stretch;
                        "
                      >
                        <div
                          style="
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px; /* 112.5% */
                            letter-spacing: -0.16px;
                          "
                        >
                          Alamat Pengiriman
                        </div>
                        <div>
                          <router-link :to="'/Users/alamat'">
                            <button
                              style="
                                display: flex;
                                width: 100px;
                                padding: 10px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                border-radius: 30px;
                                background: var(--primary-05, #244786);
                              "
                            >
                              <span
                                style="
                                  color: var(--neutral-01, #fefefe);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px; /* 110% */
                                  letter-spacing: -0.12px;
                                "
                                >Ganti Alamat</span
                              >
                            </button>
                          </router-link>
                        </div>
                      </div>

                      <span
                        style="
                          color: #000;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 18px; /* 112.5% */
                          letter-spacing: -0.16px;
                        "
                        >Alamat Utama :</span
                      >
                      <span
                        style="
                          color: #000;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 15.4px;
                          letter-spacing: -0.14px;
                        "
                      >
                        {{ address.firstname }} {{ address.lastname }}</span
                      >
                      <span
                        style="
                          color: #000;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 15.4px;
                          letter-spacing: -0.14px;
                          text-align: left;
                        "
                      >
                        {{ address.address_2 }} {{ address.zone_1 }}
                        {{ address.city }} {{ address.province }}
                      </span>
                      <span
                        style="
                          color: #000;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 15.4px;
                          letter-spacing: -0.14px;
                        "
                      >
                        {{ address.telephone }}</span
                      >
                    </div>
                  </div>
                  <div v-if="addresses.length === 0">
                    <!-- You can add a message or handle the case where no addresses with status 1 are found -->
                    <p>No addresses found with status 1</p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 col-sm-12">
                <div
                  style="
                    display: flex;
                    width: 100%;
                    height: 285px;
                    padding: 15px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 17px;
                    border-radius: 15px;
                    background: var(--neutral-01, #fefefe);
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      align-self: stretch;
                    "
                  >
                    <div
                      style="
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px;
                        letter-spacing: -0.16px;
                      "
                    >
                      Metode Pembayaran
                    </div>
                    <div>
                      <button
                        style="
                          display: flex;
                          padding: 10px;
                          justify-content: center;
                          align-items: center;
                          gap: 10px;
                          border-radius: 30px;
                          background: var(--primary-05, #244786);
                        "
                      >
                        <span
                          style="
                            color: var(--neutral-01, #fefefe);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 13.2px;
                            letter-spacing: -0.12px;
                          "
                          >Lihat Semua</span
                        >
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="custom-card">
                        <div style="padding: 5px; height: auto">
                          <center>
                            <img
                              src="../../assets/images/user/bca.png"
                              style="width: 85px; height: 56px; flex-shrink: 0"
                            />
                            <br />
                            <span
                              style="
                                color: #000;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px;
                                letter-spacing: -0.12px;
                              "
                              >Tasya Khaerani</span
                            >
                            <p
                              style="
                                color: #000;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 18px;
                                letter-spacing: -0.16px;
                              "
                            >
                              **** 1398
                            </p>
                            <span
                              style="
                                color: #000;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px;
                                letter-spacing: -0.1px;
                              "
                              >Sampai 23/03/2026</span
                            >
                          </center>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                      <div class="custom-card">
                        <div style="padding: 5px; height: auto">
                          <center>
                            <img
                              src="../../assets/images/user/mandiri.png"
                              style="width: 85px; height: 56px; flex-shrink: 0"
                            />
                            <br />
                            <span
                              style="
                                color: #000;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px;
                                letter-spacing: -0.12px;
                              "
                              >Tasya Khaerani</span
                            >
                            <p
                              style="
                                color: #000;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 18px;
                                letter-spacing: -0.16px;
                              "
                            >
                              **** 2219
                            </p>
                            <span
                              style="
                                color: #000;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px;
                                letter-spacing: -0.1px;
                              "
                              >Sampai 23/03/2026</span
                            >
                          </center>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      style="
                        color: var(--neutral-04, #989898);
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 13.2px; /* 132% */
                        letter-spacing: -0.1px;
                      "
                      >*Maksimal 3 kartu debit/kredit anda dalam 1 akun</span
                    >
                  </div>
                </div>
              </div> -->
            </div>
            <br />
            <br />
            <!-- <div class="mt-3 row" style="padding: 0px">
              <div class="col-12">
                <div
                  style="
                    display: flex;
                    width: 100%;
                    padding: 15px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 17px;
                    border-radius: 15px;
                    background: var(--neutral-01, #fefefe);
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      align-self: stretch;
                    "
                  >
                    <div
                      style="
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 18px; /* 112.5% */
                        letter-spacing: -0.16px;
                      "
                    >
                      Terkahir dilihat
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          flex-direction: column;
                          align-items: flex-start;
                          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                          border-radius: 10px;
                        "
                      >
                        <img
                          src="../../assets/images/user/pad.png"
                          style="width: 100%; height: 120px; flex-shrink: 0"
                        />
                        <p
                          style="
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                            line-height: 15.4px; /* 110% */
                            letter-spacing: -0.14px;
                            text-align: left;
                          "
                        >
                          We Bare Bears Notebook A5
                        </p>
                        <p
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            padding: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2px; /* 112.5% */
                            letter-spacing: -0.16px;
                          "
                        >
                          Rp 21.500
                        </p>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          flex-direction: column;
                          align-items: flex-start;
                          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                          border-radius: 10px;
                        "
                      >
                        <img
                          src="../../assets/images/user/pad.png"
                          style="width: 100%; height: 120px; flex-shrink: 0"
                        />
                        <p
                          style="
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                            line-height: 15.4px; /* 110% */
                            letter-spacing: -0.14px;
                            text-align: left;
                          "
                        >
                          We Bare Bears Notebook A5
                        </p>
                        <p
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            padding: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2px; /* 112.5% */
                            letter-spacing: -0.16px;
                          "
                        >
                          Rp 21.500
                        </p>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          flex-direction: column;
                          align-items: flex-start;
                          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                          border-radius: 10px;
                        "
                      >
                        <img
                          src="../../assets/images/user/pad.png"
                          style="width: 100%; height: 120px; flex-shrink: 0"
                        />
                        <p
                          style="
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                            line-height: 15.4px; /* 110% */
                            letter-spacing: -0.14px;
                            text-align: left;
                          "
                        >
                          We Bare Bears Notebook A5
                        </p>
                        <p
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            padding: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2px; /* 112.5% */
                            letter-spacing: -0.16px;
                          "
                        >
                          Rp 21.500
                        </p>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                      <div
                        style="
                          display: flex;
                          width: 100%;
                          flex-direction: column;
                          align-items: flex-start;
                          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
                          border-radius: 10px;
                        "
                      >
                        <img
                          src="../../assets/images/user/pad.png"
                          style="width: 100%; height: 120px; flex-shrink: 0"
                        />
                        <p
                          style="
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                            line-height: 15.4px; /* 110% */
                            letter-spacing: -0.14px;
                            text-align: left;
                          "
                        >
                          We Bare Bears Notebook A5
                        </p>
                        <p
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            padding: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2px; /* 112.5% */
                            letter-spacing: -0.16px;
                          "
                        >
                          Rp 21.500
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import sidebarPage from "@/components/Users/sidebar.vue";
import Image1 from "../../assets/images/user/1.png";
import Image2 from "../../assets/images/user/2.png";
import Image3 from "../../assets/images/user/3.png";
import Image4 from "../../assets/images/user/4.png";
import Image5 from "../../assets/images/user/5.png";
import Image6 from "../../assets/images/user/6.png";
export default {
  name: "DashboarduserPage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      addresses: [],
      customer: null,
      orderStatuses: [
        { name: "Dipesan", image: Image1, count: 0 },
        { name: "Diproses", image: Image2, count: 0 },
        { name: "Dikirim", image: Image3, count: 0 },
        { name: "Selesai", image: Image4, count: 0 },
        {
          name: "Dibatalkan",
          image: Image5,
          count: 0,
        },
        {
          name: "Pengembalian",
          image: Image6,
          count: 0,
        },
      ],
    };
  },
  mounted() {
    this.fetchAddresses();
    this.fetchCustomerDetails();
    this.token = localStorage.getItem("token");
    this.idCustomer = localStorage.getItem("id_customer");
    this.fetchOrders();
  },
  methods: {
    redirectToHistory() {
      this.$router.push("/Users/historyPesanan");
    },
    async fetchOrders() {
      try {
        const response = await fetch(
          `https://stagingapi.eurekabookhouse.com/order?customer_id=${this.idCustomer}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        const result = await response.json();

        if (response.ok) {
          console.log("Orders retrieved successfully:", result);
          this.updateOrderStatusCounts(result.data);
        } else {
          console.error("Error retrieving orders:", result.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    },
    updateOrderStatusCounts(data) {
      data.forEach((orderGroup) => {
        if (orderGroup.hasOwnProperty("order_counts")) {
          orderGroup.order_counts.forEach((orderCount) => {
            const orderStatusId = orderCount.order_status_id;
            const orderStatus = this.orderStatuses.find(
              (status) => status.name === this.getOrderStatusName(orderStatusId)
            );
            if (orderStatus) {
              orderStatus.count = orderCount.count;
            }
          });
        }
      });
    },
    getOrderStatusName(orderStatusId) {
      switch (orderStatusId) {
        case "0":
          return "Dipesan";
        case "15":
          return "Diproses";
        case "3":
          return "Dikirim";
        case "5":
          return "Selesai";
        case "7":
          return "Dibatalkan";
        case "6":
          return "Pengembalian";
        default:
          return "";
      }
    },
    fetchAddresses() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.com/shipping/alamat?customer_id=${idCustomer}`;

      axios
        .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.status && response.data.status.code === 200) {
            this.addresses = response.data.data.filter(
              (address) => address.status === 1
            );
          } else {
            console.error(
              "Failed to fetch addresses:",
              response.data.status.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching addresses:", error);
        });
    },
    fetchCustomerDetails() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.com/customer/detail?customer_id=${idCustomer}`;

      axios
        .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.data) {
            this.customer = response.data.data;
          } else {
            console.error(
              "Failed to fetch customer details:",
              response.data.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
        });
    },
  },
};
</script>
<style>
.badge-primary {
  background-color: #244786;
}

.position-relative {
  position: relative;
}

.text-pesanan {
  position: absolute;
  top: 0;
  left: 90%; /* Atur sesuai dengan posisi yang diinginkan */
  transform: translateY(-100%);
}

.custom-card {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 15px;
  border: 0.5px solid var(--primary-02, #b3ceff);
  background: var(--neutral-01, #fefefe);
  text-align: center;
}

.custom-card img {
  width: 85px;
  height: 56px;
}

.custom-card .card-title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.custom-card .card-number {
  font-size: 16px;
}

.custom-card .card-validity {
  font-size: 12px;
  color: #989898;
}
.text-nama {
  color: #000;
  font-size: 16px;
  font-style: normal;

  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.isi-nama {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.btn-edit {
  border-radius: 30px;
  background: var(--primary-05, #244786);
  display: flex;
  width: 60px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.txt-edit {
  color: var(--neutral-01, #fefefe);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px; /* 110% */
  letter-spacing: -0.12px;
}
.profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.bg-profile {
  display: flex;
  width: 100%;
  height: auto;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  border-radius: 15px;
  background: var(--neutral-01, #fefefe);
}
.txt-title-profile {
  color: var(--primary-05, #244786);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.bg-utama-profile {
  display: flex;
  width: 100%;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  border-radius: 15px;
  background: #fff;
}
</style>
