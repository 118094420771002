var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"mt-3 sec-relate-product bg6 mb-5"},[_c('div',{staticClass:"container bgu5 p-tb-15 p-lr-15 p-lr-5-sm bs1",staticStyle:{"padding":"15px"}},[_c('div',[(_vm.dataFlashsale)?_c('div',{staticClass:"flashsale-info mb-4"},[_vm._m(0),_c('div',{staticClass:"flashsale-timer"},[_c('p',[_vm._v(" Flash Sale berlangsung hingga: "),_c('strong',[_vm._v(_vm._s(_vm.formatDate(_vm.dataFlashsale.endTime)))])])])]):_vm._e(),_c('div',{staticClass:"mt-3 position-relative"},[_c('div',{staticClass:"d-none d-lg-block flickity-prev-next-button flickity-prev-button",staticStyle:{"position":"absolute","top":"50%","left":"5px","transform":"translateY(-50%)","z-index":"1"}},[_c('button',{staticClass:"nav-button",on:{"click":_vm.prevSlide}},[_c('i',{staticClass:"fa fa-angle-left",attrs:{"aria-hidden":"true"}})])]),(_vm.produkMaster.length > 0)?_c('flickity',{ref:"flickity",staticClass:"flickity",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.produkMaster),function(item,index){return _c('div',{key:index,staticClass:"carousel-cell wd-15 block2 bg0"},[_c('div',{staticClass:"block2-pic hov-img0 text-center",class:{ 'label-new': item.discount > 0 },attrs:{"data-label":item.discount + '%'}},[_c('img',{staticClass:"img-official",attrs:{"src":"https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"}}),_c('div',[_c('img',{staticClass:"img-responsive",style:({
                      'max-width': '100%',
                      height: 'auto',
                      cursor: 'pointer',
                      opacity: item.quantity === 0 ? 0.2 : 1,
                    }),attrs:{"src":'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                      item.productImg,"alt":""},on:{"error":_vm.handleImageError,"click":function($event){item.quantity > 0 && _vm.handleProductClick(item)}}}),(item.quantity === 0)?_c('div',{staticClass:"sold-out-label"},[_vm._v(" Habis ")]):_vm._e()])]),_c('div',{staticClass:"block2-txt flex-w flex-t p-all-8"},[_c('div',{staticClass:"block2-txt-child1 flex-col-l"},[_c('div',{staticClass:"stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6",on:{"click":function($event){return _vm.handleProductClick(item)}}},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('div',{staticClass:"stext-105"},[(item.discount > 0)?_c('strong',{staticClass:"cl13 original-price"},[_vm._v(" Rp "+_vm._s(_vm.formatPrice(item.price))+" ")]):_vm._e()]),_c('div',{staticClass:"stext-105"},[_c('strong',{staticClass:"cl13 sale-price"},[_vm._v(" Rp "+_vm._s(_vm.formatPrice( item.discount > 0 ? item.price - item.price * (item.discount / 100) : item.price ))+" ")])])])])])}),0):_vm._e(),_c('div',{staticClass:"d-none d-lg-block flickity-prev-next-button flickity-next-button",staticStyle:{"position":"absolute","top":"50%","right":"10px","transform":"translateY(-50%)"}},[_c('button',{staticClass:"nav-button",on:{"click":_vm.nextSlide}},[_c('i',{staticClass:"fa fa-angle-right",attrs:{"aria-hidden":"true"}})])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flashsale-header"},[_c('i',{staticClass:"fa fa-bolt fa-2x text-warning mr-2"}),_c('h4',{staticClass:"flashsale-title"},[_vm._v("Flash Sale")])])
}]

export { render, staticRenderFns }