<template>
  <section class="bg6 p-t-15 p-b-10">
    <form id="form-cart" class="p-b-85">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
            <sidebarPage />
          </div>

          <div class="col-lg-10 col-xl-9 m-lr-auto m-b-50 text-left">
            <div
              class="bg0 bor10 p-all-15 m-b-10 p-lr-15-sm mt-5"
              style="border-radius: 15px"
            >
              <h6 class="title-notif">Terakhir Dilihat</h6>
              <div class="mt-3 row">
                <div
                  v-for="item in productList"
                  :key="item.product_id"
                  class="col-6 col-sm-6 col-md-4 col-lg-3"
                  @click="handleProductClick(item)"
                >
                  <div>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
                        border-radius: 15px;
                        height: 260px;
                        background-color: white;
                      "
                    >
                      <div
                        class="image-container"
                        style="align-items: center; width: 100%; height: 175px"
                      >
                        <!-- Displaying the product image from API -->
                        <img
                          :src="getImageUrl(item)"
                          class="gambar-produk"
                          :style="{
                            'max-width': '100%',
                            height: 'auto',
                            cursor: 'pointer',
                            opacity: item.quantity === 0 ? 0.2 : 1,
                          }"
                          alt="Product Image"
                          @error="handleImageError"
                        />
                        <div
                          v-if="item.quantity === 0"
                          class="sold-out-label"
                          style="
                            position: absolute;
                            top: 30%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: 500;
                            padding: 8px;
                            font-size: 20px;
                            color: rgb(119, 119, 119);
                            border-radius: 5px;
                          "
                        >
                          <b>Habis</b>
                        </div>
                      </div>

                      <div class="badge-container" style="text-align: left">
                        <div style="margin-top: -23px">
                          <img
                            style="max-width: 82px; max-height: 25px"
                            src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            alt="Badge Image"
                          />
                        </div>
                      </div>

                      <div style="padding-bottom: 15px">
                        <p
                          @click="handleProductClick(item)"
                          style="
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            padding: 10px;
                            line-height: 15.4px;
                            letter-spacing: -0.14px;
                            text-align: left;
                            cursor: pointer;
                          "
                        >
                          {{ truncateText(item.productName, 20) }}
                        </p>

                        <div
                          style="
                            padding-left: 8px;
                            display: flex;
                            align-items: center;
                          "
                        >
                          <div
                            v-if="item.discount > 0"
                            class="discount-label"
                            style="
                              content: attr(data-label);
                              font-size: 15px;
                              font-weight: 700;
                              color: #fff;
                              line-height: 1.5;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              position: absolute;
                              width: 42px;
                              height: 42px;
                              border-radius: 30px;
                              padding: 0 6px;
                              background-color: red;
                              top: 9px;
                              right: 25px;
                              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
                            "
                          >
                            {{ item.discount }}%
                          </div>
                          <span
                            v-if="item.discount > 0"
                            style="
                              color: var(--neutral-04, #989898);
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <s>
                              Rp
                              {{
                                item.price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                              }}
                            </s>
                          </span>
                        </div>

                        <p
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            padding: 10px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 2px;
                            text-align: left;
                            letter-spacing: -0.16px;
                          "
                        >
                          Rp
                          {{
                            item.discount > 0
                              ? (
                                  item.price -
                                  item.price * (item.discount / 100)
                                )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                              : item.price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="mt-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import sidebarPage from "@/components/Users/sidebar.vue";
import axios from "axios";

export default {
  components: {
    sidebarPage,
  },
  name: "terakhirdilihatPage",
  data() {
    return {
      productList: [],
    };
  },
  methods: {
    fetchProducts() {
      const customer_id = localStorage.getItem("id_customer");
      const token = localStorage.getItem("token");

      axios
        .get(
          `https://stagingapi.eurekabookhouse.com/product/terakhirDilihat?id_customer=${customer_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status.code === 200) {
            this.productList = response.data.data.order;
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    },
    getImageUrl(item) {
      return item.productImg
        ? `https://cdn.eurekabookhouse.co.id/ebh/product/all/${item.productImg}`
        : "https://cdn.eurekabookhouse.co.id/ebh/product/all/71-59-020-0.jpg";
    },
    handleProductClick(item) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", item.product_id);

      // Navigate to the detail page
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: item.slug },
      });
      this.scrollToTop();
    },
    handleImageError(event) {
      event.target.src =
        "https://cdn.eurekabookhouse.co.id/ebh/product/all/71-59-020-0.jpg";
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
  },
  mounted() {
    this.fetchProducts();
  },
};
</script>
