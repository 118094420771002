<template>
  <div>
    <section class="bg6 p-t-10 p-b-10">
      <div class="container">
        <div v-if="orderLengkap.order_status_id === 0 || orderLengkap.order_status_id === 7" class="payment-timer bs1">
    <span class="label">Batas Pembayaran</span>
    <div class="timer">
      <template v-if="timeRemaining > 0">
        <span class="time-box">{{ hours }}<span class="unit">h</span></span>
        <span class="time-box">{{ minutes }}<span class="unit">m</span></span>
        <span class="time-box">{{ seconds }}<span class="unit">s</span></span>
      </template>
      <span class="expired-message" v-else>Waktu Pembayaran Habis</span>
    </div>
  </div>
        <div class="mt-3 row">
     
          <div class="col-md-10 col-lg-8 m-b-15 p-lr-0-sm text-left">
            <div class="card m-b-10 bs1" style="border-radius: 10px">
      
              <div
                class="card-order"
                :style="{
                  backgroundColor:
                    orderLengkap.order_status_id === 0
                      ? '#F5F8FF' // Dipesan
                      : orderLengkap.order_status_id === 15
                      ? '#E6EFFF' // Diproses
                      : orderLengkap.order_status_id === 3
                      ? '#E6EFFF' // Dikirim
                      : orderLengkap.order_status_id === 5
                      ? '#E8FFE4' // Selesai
                      : orderLengkap.order_status_id === 6
                      ? '#FFF0F0' // Dibatalkan
                      : orderLengkap.order_status_id === 7
                      ? '#FFF0F0' // Pengembalian
                      : '#F5FFF3', // Default jika tidak sesuai dengan kondisi di atas
                }"
              >
                <p
                  style="
                    color: var(--Neutral-07, #191717);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: -0.16px;
                  "
                >
                  Detail Orders!
                </p>
          
                <p
                  :style="{
                    color:
                      orderLengkap.order_status_id === 0
                        ? '#244786' // Dipesan
                        : orderLengkap.order_status_id === 15
                        ? '#244786' // Diproses
                        : orderLengkap.order_status_id === 3
                        ? '#244786' // Dikirim
                        : orderLengkap.order_status_id === 5
                        ? '#3D8A29' // Selesai
                        : orderLengkap.order_status_id === 7
                        ? '#E91313' // Dibatalkan
                        : orderLengkap.order_status_id === 6
                        ? '#DDA62D' // Pengembalian
                        : '#F5FFF3', // Default jika tidak sesuai dengan kondisi di atas
                  }"
                  class="text-in"
                >
                  Invoice <b>{{ orderLengkap.invoice_no }}</b>
                </p>
                <!-- <pre>{{ orderLengkap.order_id }}</pre> -->

                <!-- Card dipesan -->
                <div v-if="orderLengkap.order_status_id === 0">
                  <p
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.14px;
                    "
                  >
                    <img
                      style="width: 22px; height: 20px"
                      src="../assets/images/user/dikirim.png"
                    />
                    Dipesan
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.12px;
                    "
                  >
                    Segera lakukan pembayaran dengan mengikuti petujuk
                    pembayaran. Klik Tombol Bayar Sekarang!
                  </p>
                </div>
                <!-- Card diproses -->
                <div v-else-if="orderLengkap.order_status_id === 15">
                  <p
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.14px;
                    "
                  >
                    <img
                      style="width: 22px; height: 20px"
                      src="../assets/images/user/diproses.png"
                    />
                    Diproses
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.12px;
                    "
                  >
                    Pesanan anda sedang diproses, harap tunggu beberapa saat.
                  </p>
                </div>
                <!-- Card dikirim -->
                <div v-else-if="orderLengkap.order_status_id === 3">
                  <p
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.14px;
                    "
                  >
                    <img
                      style="width: 35px; height: 20px"
                      src="../assets/images/user/dipesan.png"
                    />
                    Dikirim
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #244786;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.12px;
                    "
                  >
                    Pesanan anda sudah dikirim, silahkan lacak pesanan anda!
                  </p>
                  <p>
                    No Resi : <b>{{ noResi }}</b>
                  </p>
                  <div class="mt-3">
                    <div v-if="isLoading">Loading...</div>
                    <div v-else>
                      <div v-if="trackingData">
                        <div class="card">
                          <div class="card-header" @click="toggleAccordion">
                            <div class="d-flex justify-content-between">
                              <div>Tracking Information</div>
                              <div class="ml-5" style="color: #091d42">
                                Tracking InformationTrackingdfgfdgdfgdfgfdgggdd
                              </div>
                              <div class="ml-5">
                                <div class="ml-5">
                                  <span style="font-size: 20px">{{
                                    isAccordionOpen ? "⬆️" : "⬇️ "
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="card-body"
                            :class="{ open: isAccordionOpen }"
                          >
                            <div class="stepper">
                              <div
                                v-for="(
                                  manifest, index
                                ) in trackingData.manifest"
                                :key="index"
                                class="step"
                              >
                                <div class="step-header">
                                  <span class="step-number">{{
                                    index + 1
                                  }}</span>
                                  <div class="step-title">
                                    <p>
                                      <b>
                                        {{ manifest.manifest_date }}
                                        {{ manifest.manifest_time }}
                                      </b>
                                      - {{ manifest.manifest_description }}
                                    </p>
                                  </div>
                                </div>
                                <div class="connector"></div>
                              </div>
                            </div>
                            <div class="status-info">
                              <p>
                                <strong>Status Pengiriman:</strong>
                                {{ trackingData.delivery_status.status }}
                              </p>
                              <p>
                                <strong>Waktu Terima:</strong>
                                {{ trackingData.delivery_status.pod_date }}
                                {{ trackingData.delivery_status.pod_time }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>
                          No resi tidak dapat di temukan, pesanan tidak dapat di
                          lacak.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Card selesai -->
                <div v-else-if="orderLengkap.order_status_id === 5">
                  <p
                    style="
                      color: #3d8a29;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.14px;
                    "
                  >
                    <img
                      style="width: 22px; height: 20px"
                      src="../assets/images/user/selesai.png"
                    />
                    Selesai
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #3d8a29;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 15.4px;
                      letter-spacing: -0.12px;
                    "
                  >
                    Pesanan anda telah selesai, terima kasih atas pembeliannya.
                  </p>
                  <div class="mt-3">
                    <div v-if="isLoading">Loading...</div>
                    <div v-else>
                      <div v-if="trackingData">
                        <div class="stepper">
                          <div
                            v-for="(manifest, index) in trackingData.manifest"
                            :key="index"
                            class="step"
                          >
                            <div class="step-header">
                              <span class="step-number">{{ index + 1 }}</span>
                              <div class="step-title">
                                <p>
                                  <b
                                    >{{ manifest.manifest_date }}
                                    {{ manifest.manifest_time }}</b
                                  >-{{ manifest.manifest_description }}
                                </p>
                              </div>
                            </div>
                            <div class="connector"></div>
                          </div>
                        </div>
                        <div class="status-info">
                          <p>
                            <strong>Status Pengiriman:</strong>
                            {{ trackingData.delivery_status.status }}
                          </p>
                          <p>
                            <strong>Waktu Terima:</strong>
                            {{ trackingData.delivery_status.pod_date }}
                            {{ trackingData.delivery_status.pod_time }}
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <p>
                          No resi tidak dapat di temukan, pesanan tidak dapat di
                          lacak.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Card dibatalkan -->
                <div v-else-if="orderLengkap.order_status_id === 7">
                  <p
                    style="
                      color: #e91313;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 13.2px; /* 110% */
                      letter-spacing: -0.12px;
                    "
                  >
                    <img
                      style="width: 22px; height: 20px"
                      src="../assets/images/user/batal.png"
                    />
                    Order Anda Dibatalkan
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #e91313;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 13.2px; /* 110% */
                      letter-spacing: -0.12px;
                    "
                  >
                    Pesanan anda telah dibatalkan, silahkan membuat pesanan
                    kembali untuk order pesanan anda yang baru.
                  </p>
                </div>
                <!-- Card diretur -->
                <div v-else-if="orderLengkap.order_status_id === 6">
                  <p
                    style="
                      color: #dda62d;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 13.2px; /* 110% */
                      letter-spacing: -0.12px;
                    "
                  >
                    <img
                      style="width: 22px; height: 20px"
                      src="../assets/images/user/retur.png"
                    />
                    Pengembalian
                  </p>
                  <p
                    class="mt-2"
                    style="
                      color: #dda62d;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 13.2px; /* 110% */
                      letter-spacing: -0.12px;
                    "
                  >
                    Pesanan anda sedang dalam proses pengembalian, harap tunggu
                    konfirmasi lebih lanjut.
                  </p>
                </div>
              </div>

              <div class="card-body">
                <p class="text-alamat">Alamat Pengiriman</p>

                <p class="text-nama mt-2">
                  {{ orderLengkap.firstname }} {{ orderLengkap.lastname }}
                </p>
                <p class="text-almt mt-2">
                  {{ orderLengkap.payment_address_2 }},
                  {{ orderLengkap.payment_zone }},
                  {{ orderLengkap.shipping_city }},
                  {{ orderLengkap.shipping_country }}
                </p>
                <p class="text-almt mt-2">{{ orderLengkap.telephone }}</p>
              </div>
            </div>

            <div v-if="orderDetails.data && orderDetails.data.length > 0">
              <div
                v-for="(order, index) in orderDetails.data[0].order"
                :key="index"
              >
                <!-- Cek apakah produk memiliki id mall yang sama dengan produk sebelumnya -->
                <template
                  v-if="
                    index === 0 ||
                    order.db_order_product.db_product.db_mall.mall_id !==
                      orderDetails.data[0].order[index - 1].db_order_product
                        .db_product.db_mall.mall_id
                  "
                >
                  <div
                    class="cart-card1 mr-2"
                    style="background-color: #ffc341; width: 100%"
                  >
                    <div class="d-flex">
                      <span
                        style="
                          color: #191717;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: 15.4px;
                          letter-spacing: -0.14px;
                        "
                        >{{ order.db_order_product.db_product.db_mall.name }}
                      </span>
                    </div>
                  </div>
                </template>
                <!-- Kartu untuk setiap produk -->
                <div class="cart-card2 mr-2" style="width: 100%">
                  <div class="d-flex">
                    <!-- Gambar produk -->
                    <img
                      @click="
                        handleProductClick(
                          order.db_order_product.db_product
                            .db_product_description.seo
                        )
                      "
                      style="width: 60px; height: 80px; margin-right: 10px"
                      :src="
                        'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                        order.db_order_product.db_product.image
                      "
                      alt="kartu-angka-dan-huruf-hijaiah"
                      onerror="this.src='https://www.eurekabookhouse.co.id/assets/front/images/eureka-bookhouse-bg-grey.jpg';"
                    />
                    <div>
                      <!-- Nama produk -->
                      <span
                        @click="
                          handleProductClick(
                            order.db_order_product.db_product
                              .db_product_description.seo
                          )
                        "
                        style="
                          color: var(--neutral-07, #191717);
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 15.4px;
                          letter-spacing: -0.14px;
                          cursor: pointer;
                        "
                      >
                        {{
                          order.db_order_product.db_product
                            .db_product_description.name
                        }}
                      </span>
                      {{
                        order.db_order_product.db_product.db_product_description
                          .product_id
                      }}
                      <!-- Jumlah dan harga produk -->
                      <div class="d-flex align-items-start mt-2">
                        <div class="mr-2">
                          <p
                            style="
                              font-size: 16px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 18px;
                              letter-spacing: -0.16px;
                            "
                          >
                            {{ order.db_order_product.quantity }}x
                          </p>
                        </div>
                        <div class="mr-2">
                          <p
                            style="
                              color: var(--primary-04, #3860a8);
                              font-size: 16px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 18px;
                              letter-spacing: -0.16px;
                            "
                          >
                            {{
                              formatPrice(
                                order.db_order_product.db_product.price *
                                  (1 -
                                    order.db_order_product.db_product.diskon /
                                      100)
                              )
                            }}
                          </p>
                        </div>
                      </div>
                      <!-- Harga total -->
                      <div class="mt-2">
                        <span
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="text-left"
                        >
                          {{
                            formatPrice(
                              order.db_order_product.quantity *
                                order.db_order_product.db_product.price *
                                (1 -
                                  order.db_order_product.db_product.diskon /
                                    100)
                            )
                          }}
                        </span>
                      </div>
                      <!-- Input tersembunyi untuk harga dan berat -->
                      <input
                        class="harga"
                        type="hidden"
                        name="harga[]"
                        :value="order.db_order_product.db_product.price"
                      />
                      <input
                        class="berat"
                        type="hidden"
                        name="berat[]"
                        :value="order.db_order_product.db_product.weight"
                      />
                    </div>
                    <!-- Review Button -->
                  </div>

                  <button
                    v-if="
                      order.order_status_id === 5 &&
                      !reviewStatus[
                        order.db_order_product.db_product.db_product_description
                          .product_id
                      ]
                    "
                    @click="
                      openModal(
                        order.db_order_product.db_product.db_product_description
                          .product_id
                      )
                    "
                    class="button-pesanan"
                    style="
                      background: var(--primary-05, #244786);
                      margin-left: auto;
                      width: 100px;
                      height: 40px;
                    "
                  >
                    <span
                      class="text-btn-pesanan"
                      style="color: var(--neutral-01, #fefefe)"
                    >
                      Review
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <!-- Handle the case when orderDetails.data is undefined or empty -->
              No order details available.
            </div>
            <div style="width: 100%; height: 1px; background: #cbcbcb"></div>

            <!-- <div class="card-catatan mt-3 mr-2" style="width: 100%">
              <h6 class="judul-catatan">Notes Pesanan</h6>
              <span class="mr-2 text-catatan"
                >Tolong dipacking rapih ya min!</span
              >
            </div> -->
            <br />
          </div>
          <div class="col-md-10 col-lg-7 col-xl-4 p-lr-0-sm">
            <div
              v-if="orderLengkap.order_status_id !== 7"
              class="card p-all-15 p-lr-15-sm mb-3 bgu3 bs1"
              style="
                border-radius: 10px;
                background: white;
                width: 100%;
                border: 0.2px solid #244786;
              "
            >
              <div class="text-center">
                <p class="stext-103">Pesanan dibuat pada</p>
                <p class="mtext-101 mt-1">
                  <b>{{
                    new Date(orderLengkap.date_added).toLocaleString("id-ID", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  }}</b>
                </p>
                <p class="text-waktu mt-1">
                  Pembayaran pesanan ini berlaku 24 jam, silahkan melakukan
                  transfer sebelum waktu yang ditentukan
                </p>
              </div>
            </div>
            <div class="card-co">
              <span
                class="mt-2"
                style="
                  color: #000;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                  text-align: left;
                "
                >Ringkasan Pesanan Anda</span
              >

              <div class="d-flex justify-content-between mt-5">
                <span
                  style="
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px;
                    letter-spacing: -0.14px;
                  "
                  >Subtotal Produk</span
                >
                <span>{{ formatPrice(orderLengkap.subtotal) }}</span>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span
                  style="
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px;
                    letter-spacing: -0.14px;
                  "
                  >Subtotal Pengiriman</span
                >
                <span>{{ formatPrice(orderLengkap.ongkoskirim) }}</span>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <span
                  style="
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15.4px;
                    letter-spacing: -0.14px;
                  "
                  >Voucher</span
                >
                <span v-if="orderLengkap.total_kupon === 0"
                  >Tidak digunakan</span
                >
                <span v-else style="color: red"
                  >- {{ formatPrice(orderLengkap.total_kupon) }}</span
                >
              </div>

              <div
                class="mt-2"
                style="width: 99%; height: 1px; background: #cbcbcb"
              ></div>

              <div class="d-flex justify-content-between mt-4">
                <span
                  style="
                    color: var(--primary-04, #3860a8);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: -0.16px;
                  "
                  >Total Pesanan</span
                >
                <span>{{ formatPrice(orderLengkap.total) }}</span>
              </div>

              <div class="mt-3">
                <div
                  v-if="
                    orderLengkap.order_status_id === 0 ||
                    orderLengkap.order_status_id === 6
                  "
                >
                  <!-- Tombol "Pilih Pembayaran" -->
                  <button
                    v-if="
                      orderLengkap.token_midtrans === null &&
                      orderLengkap.order_status_id !== 7 &&
                      orderLengkap.order_status_id !== 6
                    "
                    @click="confirmPaymentSelection"
                    class="button-pesanan"
                    style="background: var(--primary-05, #244786)"
                  >
                    <span
                      class="text-btn-pesanan"
                      style="color: var(--neutral-01, #fefefe)"
                    >
                      Pilih Pembayaran
                    </span>
                  </button>

                  <!-- Bayar Sekarang button -->
                  <button
                    v-else-if="
                      orderLengkap.order_status_id !== 7 &&
                      orderLengkap.order_status_id !== 6
                    "
                    @click="
                      setBayar(
                        orderLengkap.order_id,
                        orderLengkap.total,
                        orderLengkap.token_midtrans
                      )
                    "
                    class="button-pesanan"
                    style="background: var(--primary-05, #244786)"
                  >
                    <span
                      class="text-btn-pesanan"
                      style="color: var(--neutral-01, #fefefe)"
                    >
                      Bayar Sekarang
                    </span>
                  </button>
                </div>

                <!-- Batal button -->
                <button
                  class="button-pesanan mt-2"
                  v-if="
                    orderLengkap.order_status_id === 0 ||
                    orderLengkap.order_status_id === 15
                  "
                  @click="cancelOrder"
                  style="background: var(--danger-01, #e91313)"
                >
                  <span
                    class="text-btn-pesanan"
                    style="color: var(--danger-03, #ffd7d7)"
                  >
                    Batalkan Pesanan
                  </span>
                </button>

                <!-- Tombol "Ajukan Pengembalian" -->
                <!-- <button
                  v-if="
                    orderLengkap.order_status_id === 3 ||
                    orderLengkap.order_status_id === 6
                  "
                  @click="showReturModal"
                  style="
                    background: #ffb800;
                  "
                  class="button-pesanan"
                >
                  <span
                  class="text-btn-pesanan"
                    style="
                      color: black;
                    "
                  >
                    Ajukan Pengembalian
                  </span>
                </button> -->

                <button
                  v-if="
                    orderLengkap.order_status_id === 3 &&
                    orderLengkap.order_status_id !== 6
                  "
                  @click="ajukanPengembalian"
                  style="background: #ffb800"
                  class="button-pesanan"
                >
                  <span class="text-btn-pesanan" style="color: black">
                    Ajukan Pengembalian
                  </span>
                </button>
                <!-- Tombol "Batalkan Pengembalian" -->
                <button
                  v-if="orderLengkap.order_status_id === 6"
                  @click="batalPengembalian"
                  class="button-pesanan mt-2"
                  style="
                    background: #ffc341; /* Ubah warna latar sesuai kebutuhan */
                  "
                >
                  <span class="text-btn-pesanan" style="color: black">
                    Batalkan Pengembalian
                  </span>
                </button>

                <!-- Tombol "Pesanan Selesai" -->
                <button
                  v-if="
                    orderLengkap.order_status_id === 3 &&
                    orderLengkap.order_status_id !== 6
                  "
                  class="button-pesanan mt-2"
                  @click="pesananSelesai"
                  style="background: #43a841"
                >
                  <span class="text-btn-pesanan" style="color: white">
                    Pesanan Selesai
                  </span>
                </button>

                <!-- Review button -->
                <div>
                  <!-- Review Button -->
                  <!-- <button
                    v-if="orderLengkap.order_status_id === 5"
                    @click="openModal"
                    class="button-pesanan"
                    style="background: var(--primary-05, #244786)"
                  >
                    <span
                      class="text-btn-pesanan"
                      style="color: var(--neutral-01, #fefefe)"
                    >
                      Review
                    </span>
                  </button> -->

                  <!-- Review Modal -->
                </div>

                <!-- Beli Lagi button -->
                <button
                  v-if="orderLengkap.order_status_id === 7"
                  @click="addToBuy"
                  class="button-pesanan"
                  style="background: var(--primary-05, #244786)"
                >
                  <span
                    class="text-btn-pesanan"
                    style="color: var(--neutral-01, #fefefe)"
                  >
                    Beli Lagi
                  </span>
                </button>
              </div>

              <br />
            </div>
          </div>
        </div>
        <div v-if="showModal" class="modal-overlay">
          <div class="modal-content">
            <h2>Review Produk</h2>
            <hr />
            <form @submit.prevent="submitReview">
              <div class="form-group">
                <label style="display: flex; justify-content: start" for="text"
                  >Review</label
                >
                <textarea
                  id="text"
                  v-model="review.text"
                  placeholder="Bagikan penilaianmu dan bantu Pengguna lain membuat pilihan yang lebih baik!"
                  required
                ></textarea>
              </div>
              <div class="form-group">
                <label
                  style="display: flex; justify-content: start"
                  for="rating"
                  >Kualitas Produk</label
                >
                <div class="star-rating">
                  <span
                    v-for="star in stars"
                    :key="star"
                    @click="setRating(star)"
                    :class="{
                      star: true,
                      'star-filled': star <= review.rating,
                    }"
                  >
                    &#9733;
                  </span>
                </div>
              </div>
              <div class="button-group">
                <button type="button" @click="closeModal" class="cancel-button">
                  Cancel
                </button>
                <button type="submit" class="kirim-button">Kirim</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "pesananPage",
  data() {
    return {
      currentTime: new Date(),

      orderDetails: {},
      orderLengkap: {},
      deskripsiProduk: "",
      trackingData: null,
      isLoading: false,
      noResi: "",
      reviewStatus: {}, // Object to keep track of review status by product_id
      selectedProductId: null,
      showModal: false,
      review: {
        text: "",
        rating: "",
      },
      stars: [1, 2, 3, 4, 5],
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
    this.fetchTrackingData();
    this.fetchOrderDetails();
    let midtransScript = document.createElement("script");
    //PRODUCTION
    midtransScript.setAttribute("src", "https://app.midtrans.com/snap/snap.js");
    //SANDBOX
    // midtransScript.setAttribute(
    //   "src",
    //   "https://app.sandbox.midtrans.com/snap/snap.js"
    // );
    //PRODUCTION
    midtransScript.setAttribute(
      "data-client-key",
      "Mid-client-iS2ydMsFEsJ1M7q_"
    );

    //SANDBOX
    // midtransScript.setAttribute(
    //   "data-client-key",
    //   "SB-Mid-client-cRsCt8_TADX5ME2s"
    // );
    document.head.appendChild(midtransScript);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    timeLimit() {
      const orderDate = new Date(this.orderLengkap.date_added);
      return new Date(orderDate.getTime() + 24 * 60 * 60 * 1000); // 24 jam
    },
    timeRemaining() {
      const diff = this.timeLimit - this.currentTime;
      return diff > 0 ? diff : 0;
    },
    hours() {
      return Math.floor(this.timeRemaining / (1000 * 60 * 60));
    },
    minutes() {
      return Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    },
    seconds() {
      return Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
    },
  },

  methods: {
    setRating(star) {
      this.review.rating = star;
    },
    async submitReview() {
      const product_id = this.selectedProductId;
      const customer_id = localStorage.getItem("id_customer");
      const token = localStorage.getItem("token");
      const author = `${this.orderLengkap.firstname} ${this.orderLengkap.lastname}`;

      const reviewData = {
        product_id,
        customer_id,
        author,
        text: this.review.text,
        rating: this.review.rating,
      };

      try {
        const response = await fetch(
          "https://stagingapi.eurekabookhouse.com/review/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reviewData),
          }
        );

        const data = await response.json();
        if (data.status.code === 200) {
          // Display success message using SweetAlert
          await Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: data.status.message,
            confirmButtonColor: "#3860A8",
          });
          // Update the review status for the specific product
          this.$set(this.reviewStatus, product_id, true);
          this.closeModal();
        } else {
          // Display error message using SweetAlert
          await Swal.fire({
            icon: "error",
            title: "Failed to Submit Review",
            text: "There was an issue submitting your review. Please try again.",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        // Display error message using SweetAlert
        await Swal.fire({
          icon: "error",
          title: "Submission Error",
          text: "An error occurred while submitting your review. Please try again.",
        });
      }
    },
    openModal(product_id) {
      this.selectedProductId = product_id;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    async ajukanPengembalian() {
      const formData = new FormData();
      formData.append("order_id", this.produkId);
      const token = "qtUPRXyF8NsA3U37wcPNMw==";

      try {
        // Kirim permintaan ke API
        const response = await fetch(
          "https://ujianonline.eurekabookhouse.co.id/order/order_return",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          // Tampilkan SweetAlert 2 setelah berhasil mengirim permintaan
          await Swal.fire({
            icon: "success",
            title: "Pengembalian diajukan!",
            text: "Terima kasih atas pengajuan pengembalian.",
          });

          // Refresh halaman
          location.reload();
        } else {
          throw new Error("Gagal mengajukan pengembalian");
        }
      } catch (error) {
        // Tangani kesalahan
        console.error("Terjadi kesalahan:", error);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan. Silakan coba lagi nanti.",
        });
      }
    },
    async fetchTrackingData(noResi) {
      try {
        this.isLoading = true;
        const response = await axios.post(
          "https://apirace.eurekalogistics.co.id/price/cek-kiriman",
          {
            resi: noResi,
          }
        );
        this.trackingData = response.data.data.rajaongkir.result;
      } catch (error) {
        console.error("Error fetching tracking data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    showReturModal() {
      let deskripsiProduk = ""; // Menyimpan deskripsi produk

      // Loop untuk mengakses setiap produk dalam pesanan
      this.orderDetails.data[0].order.forEach((order, index) => {
        // Mengakses data produk dari objek order
        const namaProduk =
          order.db_order_product.db_product.db_product_description.name;
        const gambarProduk =
          "https://cdn.eurekabookhouse.co.id/ebh/product/all/" +
          order.db_order_product.db_product.image;
        const hargaProduk = this.formatPrice(
          order.db_order_product.db_product.price
        );

        // Menambahkan informasi produk ke deskripsi
        deskripsiProduk += `
  <div style="display: flex; align-items: center; justify-content: center;">
    <img src="${gambarProduk}" alt="${namaProduk}" style="max-width: 100px; max-height: 100px;">
    <div style="margin-left: 10px;">
      <div class="text-left">${namaProduk}</div>
      <div class="text-left">${hargaProduk}</div>
    </div>
    <hr/>
  </div>
`;
      });

      // Ambil jumlah pengembalian dari orderLengkap.subtotal_final
      const jumlahPengembalian = this.formatPrice(
        this.orderLengkap.subtotal_final,
        this.orderLengkap.amount
      );

      Swal.fire({
        title: "Ajukan Pengembalian",
        html: `
      ${deskripsiProduk}

      <div class="mb-3 mt-4">
        <label  for="alasanPengembalian" class="form-label">Pilih Alasan Pengembalian:</label>
        <select style="width:80%" id="alasanPengembalian" class="swal2-select">
          <option value="1">Produk tidak sesuai deskripsi</option>
          <option value="2">Produk rusak saat pengiriman</option>
          <option value="3">Lainnya</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="jumlahPengembalian" class="form-label">Jumlah Pengembalian:</label>
        <input style="width:80%" id="jumlahPengembalian" class="swal2-input" type="text" value="${jumlahPengembalian}" readonly>
      </div>
      <div class="mb-3">
        <label for="tujuanPengembalian" class="form-label">Pilih Tujuan Pengembalian Dana:</label>
        <select style="width:80%" id="tujuanPengembalian" class="swal2-select">
          <option value="1">Kembali ke rekening bank</option>
          <option value="2">Dompet elektronik</option>
          <option value="3">Lainnya</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="deskripsiAlasanPengembalian" class="form-label">Deskripsi Alasan Pengembalian:</label>
        <textarea id="deskripsiAlasanPengembalian" class="swal2-textarea full-size" placeholder="Deskripsi Alasan Pengembalian"></textarea>
      </div>
    `,
        showCancelButton: true,

        cancelButtonText: "Batal",
        confirmButtonText: "Kirim",
        confirmButtonColor: "#3860A8",
        focusConfirm: false,
        preConfirm: () => {
          const alasanPengembalian = Swal.getPopup().querySelector(
            "#alasanPengembalian"
          ).value;
          const tujuanPengembalian = Swal.getPopup().querySelector(
            "#tujuanPengembalian"
          ).value;
          const deskripsiAlasanPengembalian = Swal.getPopup().querySelector(
            "#deskripsiAlasanPengembalian"
          ).value;

          // Validasi jika ada form yang tidak diisi
          if (
            !alasanPengembalian ||
            !tujuanPengembalian ||
            !deskripsiAlasanPengembalian
          ) {
            Swal.showValidationMessage("Semua form harus diisi");
          }

          return {
            alasanPengembalian: alasanPengembalian,
            tujuanPengembalian: tujuanPengembalian,
            deskripsiAlasanPengembalian: deskripsiAlasanPengembalian,
          };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Jika tombol "Kirim" diklik, Anda dapat menangani data pengembalian di sini
          const {
            alasanPengembalian,
            tujuanPengembalian,
            deskripsiAlasanPengembalian,
          } = result.value;
          console.log("Alasan Pengembalian:", alasanPengembalian);
          console.log("Tujuan Pengembalian Dana:", tujuanPengembalian);
          console.log(
            "Deskripsi Alasan Pengembalian:",
            deskripsiAlasanPengembalian
          );

          // Lakukan apa yang perlu dilakukan setelah mengirim data pengembalian
          Swal.fire({
            title: "Sukses!",
            text: "Permintaan pengembalian telah diajukan.",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#3860A8",
          });
        }
      });
    },
    handleProductClick(seo) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", seo);

      // Navigate to the detail page with the slug as a parameter
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: seo },
      });
    },
    async addToBuy() {
      const apiUrl = "https://stagingapi.eurekabookhouse.com/cart/add";
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const orderProduct = this.orderDetails.data[0].order[0].db_order_product;
      const productDescription = orderProduct.db_product.db_product_description;

      // Ensure idCustomer is not undefined
      if (idCustomer === null) {
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Gagal",
          text: "Gagal menambahkan produk ke keranjang: ID Customer tidak ditemukan",
        });
        return;
      }

      // Check if the user is logged in
      if (!token) {
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Upss",
          text: "Tidak dapat membeli, Anda belum login!",
        }).then(() => {
          this.$router.push("/UserEBH/login"); // Redirect to the login page
        });
        return;
      }
      const requestBody = {
        customer_id: idCustomer,
        id_produk: productDescription.product_id,
        id: idCustomer,
        qty: orderProduct.quantity,
        is_elibu: orderProduct.db_product.is_elibu,
        is_checked: true,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        const responseData = await response.json();

        if (response.ok) {
          // Success, display success alert and redirect
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "success",
            title: "Berhasil",
            text: responseData.status.message,
          }).then(() => {
            this.$router.push("/cart"); // Redirect to the cart page
          });
        } else {
          if (response.status === 401) {
            // Unauthorized, display custom message and redirect to login
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Upss",
              text: "Sepertinya Anda harus login kembali",
            }).then(() => {
              // Redirect to the login page
              this.$router.push("/UserEBH/login");
            });
          }
          if (
            responseData.status &&
            responseData.status.code === 400 &&
            responseData.status.message ===
              "Gagal Update Qty keranjang. Quantity produk tidak mencukupi."
          ) {
            // Display SweetAlert for specific error message without redirect
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Gagal",
              text: responseData.status.message,
            });
          } else {
            // Failure, display error alert with the message from API and redirect
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Gagal",
              text: responseData.status.message,
            });
          }
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
        Swal.fire({
          confirmButtonColor: "#3860A8",
          icon: "error",
          title: "Terjadi kesalahan",
          text: "Terjadi kesalahan saat menambahkan produk ke keranjang",
        });
      }
    },
    async cancelOrder() {
      // Menampilkan dialog konfirmasi
      const confirmed = await Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda akan membatalkan pesanan.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3860A8",
        confirmButtonText: "Ya, batalkan!",
      });

      // Jika pengguna mengklik "Ya"
      if (confirmed.isConfirmed) {
        const token = localStorage.getItem("token");
        const idCustomer = localStorage.getItem("id_customer");
        const selectedOrderId = localStorage.getItem("selectedOrderId");

        axios
          .post(
            "https://stagingapi.eurekabookhouse.com/order/cencel",
            {
              customer_id: idCustomer,
              order_id: selectedOrderId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            // Menampilkan pesan keberhasilan menggunakan SweetAlert2
            Swal.fire({
              title: "Pesanan Dibatalkan!",
              confirmButtonColor: "#3860A8",
              text: "Pesanan berhasil dibatalkan.",
              icon: "success",
            }).then(() => {
              // Me-refresh halaman setelah mengklik "Ok"
              window.location.reload();
            });
          })
          .catch((error) => {
            console.error("Error canceling order:", error);
            // Menampilkan pesan kesalahan menggunakan SweetAlert2
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Error!",
              text: "Terjadi kesalahan saat membatalkan pesanan.",
              icon: "error",
            });
          });
      }
    },

    setBayar(orderId, total, token) {
      console.log(orderId, total, token);
      const tokenMidtrans = token;
      console.log(token);
      snap.pay(tokenMidtrans, {
        onSuccess: function (result) {
          console.log("success");
          console.log("success", result);
        },
        onPending: function (result) {
          console.log("pending");
          console.log(result);
        },
        onError: function (result) {
          console.log("error");
          console.log(result);
        },
        onClose: function () {
          console.log(
            "customer closed the popup without finishing the payment"
          );
        },
      });
    },
    setPayment(orderId, total) {
      const gthis = this;
      axios
        .post("https://stagingapi.eurekabookhouse.com/order/mitrans", {
          order_id: orderId,
          subtotal_final: total,
        })
        .then((res) => {
          this.$router.go();
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            // Handle 404 error - show alert and cancel order
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Pesanan Telah Kadarluarsa!",
              text: "Maaf, pesanan tidak dapat dilanjutkan karena waktu pembayaran telah berakhir. Mohon untuk melakukan pemesanan kembali atau membatalkan pesanan Anda.",
              icon: "error",
            }).then(() => {
              this.cancelOrder();
            });
          } else {
            console.error("Error processing payment:", error);
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Error!",
              text: "Terjadi kesalahan saat memproses pembayaran.",
              icon: "error",
            });
          }
        });
    },
    confirmPaymentSelection() {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin untuk melanjutkan pembayaran?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, lanjutkan!",
        confirmButtonColor: "#3860A8",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setPayment(
            this.orderLengkap.order_id,
            // this.orderLengkap.subtotal_final
            this.orderLengkap.total
          );
        }
      });
    },
    formatPrice(price) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(price);
    },
    async batalPengembalian() {
      try {
        const token = "qtUPRXyF8NsA3U37wcPNMw=="; // Ganti dengan token Anda

        // Membuat objek FormData
        const formData = new FormData();
        formData.append("order_id", this.produkId); // Menambahkan order_id ke FormData

        // Mengirim permintaan POST dengan token ke API menggunakan FormData
        const response = await axios.post(
          "https://ujianonline.eurekabookhouse.co.id/order/order_complete",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Menambahkan token ke header
              "Content-Type": "multipart/form-data", // Menetapkan header Content-Type untuk FormData
            },
          }
        );

        // Tampilkan alert menggunakan SweetAlert2
        await Swal.fire({
          title: "Batalkan Pengembalian!",
          text: "Pesanan telah berhasil diselesaikan.",
          icon: "success",
          confirmButtonColor: "#3860A8",
          confirmButtonText: "OK",
        });

        // Lakukan reload halaman setelah alert diklik OK
        location.reload();
      } catch (error) {
        console.error(
          "Terjadi kesalahan saat mengirim pesanan selesai:",
          error
        );
        // Tampilkan alert jika terjadi kesalahan
        Swal.fire({
          title: "Error",
          text: "Terjadi kesalahan saat mengirim pesanan selesai. Silakan coba lagi.",
          icon: "error",
          confirmButtonColor: "#3860A8",
          confirmButtonText: "OK",
        });
      }
    },
    async pesananSelesai() {
      try {
        const token = "qtUPRXyF8NsA3U37wcPNMw=="; // Ganti dengan token Anda

        // Membuat objek FormData
        const formData = new FormData();
        formData.append("order_id", this.produkId); // Menambahkan order_id ke FormData

        // Mengirim permintaan POST dengan token ke API menggunakan FormData
        const response = await axios.post(
          "https://ujianonline.eurekabookhouse.co.id/order/order_complete",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Menambahkan token ke header
              "Content-Type": "multipart/form-data", // Menetapkan header Content-Type untuk FormData
            },
          }
        );

        // Tampilkan alert menggunakan SweetAlert2
        await Swal.fire({
          title: "Pesanan Selesai",
          text: "Pesanan telah berhasil diselesaikan.",
          icon: "success",
          confirmButtonColor: "#3860A8",
          confirmButtonText: "OK",
        });

        // Lakukan reload halaman setelah alert diklik OK
        location.reload();
      } catch (error) {
        console.error(
          "Terjadi kesalahan saat mengirim pesanan selesai:",
          error
        );
        // Tampilkan alert jika terjadi kesalahan
        Swal.fire({
          title: "Error",
          text: "Terjadi kesalahan saat mengirim pesanan selesai. Silakan coba lagi.",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#3860A8",
        });
      }
    },
    fetchOrderDetails() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const selectedOrderId = localStorage.getItem("selectedOrderId");

      axios
        .get(
          `https://stagingapi.eurekabookhouse.com/order/detail?customer_id=${idCustomer}&order_id=${selectedOrderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.orderDetails = response.data;
          this.orderLengkap = response.data.data[0].order[0];
          console.log("inii res vouc", this.orderLengkap);

          this.produkId = response.data.data[0].order[0].order_id;
          console.log("inii id produl", this.produkId);
          this.noResi = response.data.data[0].order[0].awb;
          console.log("inii no resi", this.noResi);

          // Panggil fetchTrackingData dengan noResi yang diperoleh dari response
          this.fetchTrackingData(this.noResi);
        })
        .catch((error) => {
          console.error("Error fetching order details:", error);
        });
    },
  },
};
</script>
<style>
.button-pesanan {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
}
.text-btn-pesanan {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16px;
}
.stepper {
  display: flex;
  flex-direction: column;
}

.step {
  display: flex;
  margin-bottom: 20px;
  position: relative;
}

.step-header {
  display: flex;
  align-items: flex-start;
}

.step-number {
  background-color: #091d42;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.step-title {
  margin-left: 10px;
}

.connector {
  width: 2px;
  background-color: #007bff;
  height: calc(100% - 11px);
  position: absolute;
  top: 30px;
  left: 12px;
}

.status-info {
  margin-top: 15px;
}

/* Menghapus garis pada langkah terakhir */
.step:last-child .connector {
  display: none;
}

/* Style untuk status terakhir */
.step:last-child .step-number {
  background-color: #28a745;
  color: #28a745;
}
.full-size {
  width: 80%;
  height: 150px; /* Sesuaikan tinggi textarea sesuai kebutuhan Anda */
  padding: 0;
}
.card-order {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px 10px 0px 0px;
  background: var(--primary-05, #244786);
}
.text-in {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px;
  letter-spacing: -0.14px;
}
.text-order {
  color: var(--Neutral-07, #191717);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.16px;
}
.text-invoice {
  color: #3d8a29;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px;
  letter-spacing: -0.14px;
}
.text-alamat {
  color: var(--neutral-06, #4c4949);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-nama {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-almt {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.judul-catatan {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.text-catatan {
  color: var(--neutral-06, #4c4949);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-waktu {
  color: var(--neutral-07, #191717);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px; /* 110% */
  letter-spacing: -0.12px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the modal is on top */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid #244786; /* Increased border thickness */
  border-radius: 10px;
  resize: vertical;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.form-group textarea:focus {
  border-color: #244786; /* Change border color on focus */
  outline: none; /* Remove default focus outline */
}
label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
  box-sizing: border-box;
}
.star-rating {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border: 2px solid #244786; /* Increased border thickness */
  border-radius: 10px;
  resize: vertical;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.star {
  font-size: 30px;
  color: #d3d3d3;
  cursor: pointer;
  transition: color 0.3s;
}

.star-filled {
  color: #ffd700;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background: #244786;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: background 0.3s;
}

.kirim-button {
  background: #1d3a6a;
}
.cancel-button {
  background: #e74c3c;
}

.cancel-button:hover {
  background: #c0392b;
}
/* Gaya Umum */
.stepper {
  margin-top: 10px;
  padding: 10px;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  /* background-color: #f9f9f9; */
}

.step-header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}

.step-number {
  font-size: 10px;
  font-weight: bold;
  color: #091d42;
}

.step-title p {
  margin: 0;
  font-size: 16px;
  color: #091d42;
}

.connector {
  width: 2px;
  background-color: #091d42;
  height: 70px;
  margin-left: 10px;
  margin-top: 10px;
}

.status-info {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}
.payment-timer {
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #FFC341;
  padding: 10px 10px;
  border-radius: 8px;
  color: #ffffff;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin-left: 13px;
}

.label {
  margin-right: 15px;
  font-size: 16px;
}

.timer {
  display: flex;
  gap: 8px;
}

.time-box {
  background: #ffffff;
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.unit {
  margin-left: 4px;
  font-size: 14px;
  color: #666;
}
.expired-message {
  background: #ff5555;
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}
</style>
