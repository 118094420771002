<template>
  <div>
    <!-- <div class="p-tb-30">
     
    </div> -->
    <div
      class="mt-5 bg0 bor10 p-all-15 m-b-10 p-lr-15-sm"
      style="border-radius: 15px"
    >
      <div class="avatar-profi mr-2">
        <!-- <img
          class="img-thumbnail"
          :src="getAvatarUrl()"
          style="width: 100px; height: 100px; border-radius: 50%"
        /> -->
        <img
          class="img-thumbnail"
          src="https://tse4.mm.bing.net/th?id=OIP.l54ICAiwopa2RCt7J2URWwHaHa&pid=Api&P=0&h=180"
          style="width: 100px; height: 100px; border-radius: 50%"
        />
      </div>
      <p class="stext-102 cl2">
        Halo, <b>{{ customer.firstname }} {{ customer.lastname }}</b>
      </p>
      <p
        class="m-b-5 text1l"
        style="
          color: var(--neutral-04, #989898);

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 15.4px; /* 110% */
          letter-spacing: -0.14px;
        "
      >
        <i class="fa fa-envelope m-r-10 fs-20"></i>
        {{ customer.email }}
      </p>
      <router-link :to="'/Users/editProfile'">
        <p
          class="m-b-5 text1l text-center"
          style="
            color: var(--neutral-04, #989898);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            line-height: 15.4px; /* 110% */
            letter-spacing: -0.14px;
            text-align: left;
            color: #3860a8;
          "
        >
          <i class="fa fa-pencil m-r-10 fs-20"></i>
          Edit Profil
        </p>
      </router-link>
      <hr />
      <p class="stext-101 cl2 p-b-6 text-left"><b>Akun</b></p>
      <div class="flex-w flex-t bor12 p-b-13 stext-102 text-left">
        <ul>
          <li>
            <router-link :to="'/Users/Dashboarduser'"
              ><span style="color: #143164">Dashboard</span></router-link
            >
          </li>
          <li>
            <router-link :to="'/Users/Profile'"
              ><span style="color: #143164">Profil Saya</span></router-link
            >
          </li>
          <!-- <li><a class="cl1" href="">Alamat Akun</a></li> -->
          <li>
            <router-link :to="'/Users/terakhirdilihat'"
              ><span style="color: #143164">Terakhir Dilihat</span></router-link
            >
          </li>
          <li style="color: #143164">
            <router-link :to="'/Users/notificationprofile'"
              ><span style="color: #143164">Pemberitahuan</span></router-link
            >
          </li>
        </ul>
      </div>
      <p class="stext-101 cl2 p-tb-6 text-left"><b>Transaksi</b></p>
      <div class="flex-w flex-t bor12 p-b-13 stext-102 text-left">
        <ul>
          <li style="color: #143164">
            <router-link :to="'/Users/alamat'"
              ><span style="color: #143164">Alamat Kirim</span></router-link
            >
          </li>
          <!-- <li>
            <router-link :to="'/Users/metodebayar'"
              ><span style="color: #143164">Metode Bayar</span></router-link
            >
          </li> -->
          <li>
            <router-link :to="'/Users/historyPesanan'"
              ><span style="color: #143164">History Pesanan</span></router-link
            >
          </li>
          <!-- <li><a class="cl1" href="https://www.eurekabookhouse.co.id/frontend/account/kupon">Kupon</a></li> -->
          <!-- <li><a class="cl1" href="">Eureka! Poin</a></li> -->
        </ul>
      </div>
      <!-- <p class="stext-101 cl2 p-t-6 p-b-6 text-left">
                  <b>Pesanan</b>
                </p>
                <div class="flex-w flex-t p-b-13 stext-102 text-left">
                  <ul>
                    <li>
                      <a
                        class="cl1"
                        style="color: #143164"
                        href="https://eurekabookhouse.co.id/frontend/account/history/get/0"
                        >History Pesanan</a
                      >
                    </li>
                    <li>
                      <a
                        style="color: #143164"
                        class="cl1"
                        href="https://eurekabookhouse.co.id/frontend/account/produkdigital"
                        >Produk Digital</a
                      >
                    </li>
                    <li>
                      <a
                        style="color: #143164"
                        class="cl1"
                        href="https://eurekabookhouse.co.id/frontend/account/ticket"
                        >Pesanan Tiket</a
                      >
                    </li>
                    <li>
                      <a
                        style="color: #143164"
                        class="cl1"
                        href="https://eurekabookhouse.co.id/frontend/account/notification/"
                        >Pemberitahuan</a
                      >
                    </li>
                  </ul>
                </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sidebarPage",
  data() {
    return {
      customer: null,
      // customer: {
      //   firstname: "",
      //   lastname: "",
      //   email: "",
      //   telephone: "",
      //   avatar: "",
      // },
    };
  },
  mounted() {
    // Panggil metode untuk memuat detail pelanggan dari localStorage saat komponen dimuat
    this.loadCustomerDetailsFromLocalStorage();
    this.fetchCustomerDetails();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0, // Atur posisi scroll ke bagian atas halaman
        behavior: "smooth", // Efek smooth scrolling
      });
    },
    fetchCustomerDetails() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.com/customer/detail?customer_id=${idCustomer}`;

      axios
        .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.data) {
            this.customer = response.data.data;
          } else {
            console.error(
              "Failed to fetch customer details:",
              response.data.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
        });
      this.scrollToTop();
    },
    // Metode untuk memuat detail pelanggan dari localStorage
    loadCustomerDetailsFromLocalStorage() {
      const customerDetails = localStorage.getItem("customer_details");

      if (customerDetails) {
        this.customer = JSON.parse(customerDetails);
      }
      this.scrollToTop();
    },
    getAvatarUrl() {
      // Check if customer.avatar exists
      if (this.customer.avatar) {
        // If it exists, return the full URL
        return `https://stagingapi.eurekabookhouse.com/${this.customer.avatar}`;
      } else {
        // If it doesn't exist, return the default avatar URL
        return "https://www.eurekabookhouse.co.id/assets/uplod/default-avatar.png";
      }
      this.scrollToTop();
    },
  },
};
</script>
