var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.produkMaster.length > 0)?_c('flickity',{ref:"flickity",staticClass:"flickity",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.produkMaster),function(item,index){return _c('div',{key:index,staticClass:"carousel-cell wd-15 block2 bg0"},[_c('div',{staticClass:"block2-pic hov-img0 text-center",class:{ 'label-new': item.discount > 0 },attrs:{"data-label":item.discount + '%'}},[_c('img',{staticClass:"img-official",attrs:{"src":"https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"}}),_c('div',[_c('img',{staticClass:"img-responsive",style:({
              'max-width': '100%',
              height: 'auto',
              cursor: 'pointer',
              opacity: item.quantity === 0 ? 0.2 : 1,
            }),attrs:{"src":'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
              item.productImg,"alt":""},on:{"error":_vm.handleImageError,"click":function($event){item.quantity > 0 && _vm.handleProductClick(item)}}}),(item.quantity === 0)?_c('div',{staticClass:"sold-out-label",staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","padding":"8px","font-size":"20px","color":"rgb(119, 119, 119)","border-radius":"5px"}},[_vm._v(" Habis ")]):_vm._e()])]),_c('div',{staticClass:"block2-txt flex-w flex-t p-all-8"},[_c('div',{staticClass:"block2-txt-child1 flex-col-l"},[_c('div',{staticClass:"stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6",staticStyle:{"color":"black","text-align":"left","font-weight":"500","font-size":"14px","cursor":"pointer"},on:{"click":function($event){return _vm.handleProductClick(item)}}},[_vm._v(" "+_vm._s(item.productName)+" ")]),_c('div',{staticClass:"stext-105"},[_c('strong',{staticClass:"cl13",staticStyle:{"color":"var(--neutral-04, #989898)","font-size":"12px","font-style":"normal","font-weight":"400","line-height":"13.2px","/* 110% */\n                letter-spacing":"-0.12px","text-decoration":"line-through","cursor":"pointer"}},[_vm._v(" Rp "+_vm._s(item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."))+" ")])]),_c('div',{staticClass:"stext-105"},[_c('strong',{staticClass:"cl13",staticStyle:{"color":"var(--primary-05, #244786)","font-size":"16px","font-style":"normal","font-weight":"600","line-height":"18px","/* 112.5% */\n                letter-spacing":"-0.16px","cursor":"pointer"}},[_vm._v(" Rp "+_vm._s((item.price - item.price * (item.discount / 100)) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, "."))+" ")])])])])])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }