<template>
  <div>
    <section class="bg6 m-t-15">
      <div class="container p-lr-0 bg0 bor8">
        <div class="mall-banner">
          <img
            src="http://cdn.eurekabookhouse.co.id/ebh/mall/Buku-Erlangga_header_mall11_48_48.png"
          />
        </div>
        <div class="mall-detail p-all-15">
          <div class="row">
            <div class="col-lg-10 col-md-10">
              <div class="flex-w">
                <div
                  class="logo-mall bs1"
                  style="
                    background: #ffffff
                      url('https://cdn.eurekabookhouse.co.id/ebh/mall/Buku-Erlangga_icon_mall10_18_24.png')
                      no-repeat center center;
                    background-size: contain;
                    border: 2px solid #eee;
                  "
                ></div>
                <div class="detail-mall flex-m">
                  <div>
                    <h1 class="ltext-101">
                      <b>Buku Erlangga</b>
                      <span class="mtext-101">
                        <i class="fa fa-star"></i><i class="fa fa-star"></i
                        ><i class="fa fa-star"></i><i class="fa fa-star"></i>
                      </span>
                    </h1>
                    <p class="stext-107 cl1">
                      <i class="fa fa-map-marker m-r-10"></i>Jakarta Timur |
                      <span class="stext-107 cl3">Total 8930 produk</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 flex-m">
              <div class="pt-3">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/mall/detail/bukuerlangga"
                  target="_blank"
                  class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"
                  ><i class="fa fa-facebook"></i
                ></a>
                <a
                  href="https://twitter.com/share?u=https://www.eurekabookhouse.co.id/mall/detail/bukuerlangga"
                  target="_blank"
                  class="p-lr-13 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"
                  ><i class="fa fa-twitter"></i
                ></a>
                <a
                  href="https://plus.google.com/share?u=https://www.eurekabookhouse.co.id/mall/detail/bukuerlangga"
                  target="_blank"
                  class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 bor4 customer share"
                  ><i class="fa fa-google-plus"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg6 p-t-15 p-b-10">
      <div class="container p-lr-0">
        <div class="row">
          <div class="col-lg-12 col-xl-12 m-lr-auto">
            <div class="m-b-10 p-lr-15-sm">
              <div class="row">
                <div class="col-lg-3 col-md-3" id="menu_side">
                  <div class="p-all-15 bg0">
                    <div class="p-b-18">
                      <p class="mtext-101 cl2 p-b-10 text-left">
                        <b> Kategori Toko</b>
                      </p>
                    </div>
                    <div class="scrollbar" id="">
                      <div class="content">
                        <div id="cc">
                          <div class="flex-w flex-t bor12 p-b-13 text-left">
                            <ul id="tree2" class="tree">
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/3?sort=terbaru"
                                >
                                  <u><b>Buku Pelajaran </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/4?sort=terbaru"
                                      >Buku SD</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/5?sort=terbaru"
                                      >Buku SMP</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/8?sort=terbaru"
                                      >Buku TK</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/9?sort=terbaru"
                                  ><u><b>Perguruan Tinggi </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/11?sort=terbaru"
                                      >Akuntansi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/13?sort=terbaru"
                                      >Bahasa &amp; Sastra</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/14?sort=terbaru"
                                      >Ekonomi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/15?sort=terbaru"
                                      >Hukum</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/16?sort=terbaru"
                                      >Kedokteran</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/19?sort=terbaru"
                                      >Lecture Notes</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/20?sort=terbaru"
                                      >Manajamen</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/35?sort=terbaru"
                                      >Jurnalistik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/200?sort=terbaru"
                                      >Komputer</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/36?sort=terbaru"
                                  ><u><b>Buku Umum </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/1?sort=terbaru"
                                      >Buku Agama</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/37?sort=terbaru"
                                      >Biografi dan Otobiografi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/38?sort=terbaru"
                                      >Komik dan Novel</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/39?sort=terbaru"
                                      >Masakan dan Minuman</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/45?sort=terbaru"
                                      >Anak dan Remaja</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/46?sort=terbaru"
                                      >Ekonomi dan Bisnis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/47?sort=terbaru"
                                      >Hukum, Sosial dan Politik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/48?sort=terbaru"
                                      >Kesehatan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/49?sort=terbaru"
                                      >Hobi dan Hastakarya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/50?sort=terbaru"
                                      >Komputer</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/51?sort=terbaru"
                                      >Parenting</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/60?sort=terbaru"
                                      >Buku Parenting</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/167?sort=terbaru"
                                      >Kamus dan Bahasa</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/199?sort=terbaru"
                                      >Komik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/207?sort=terbaru"
                                      >Motivasi Dan Inspirasi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/313?sort=terbaru"
                                      >Buku Umum Lainnya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/315?sort=terbaru"
                                      >Psikology</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/40?sort=terbaru"
                                  ><u><b>Buku Soal </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/41?sort=terbaru"
                                      >Soal SD</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/42?sort=terbaru"
                                      >Soal SMP</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/43?sort=terbaru"
                                      >Soal SMA</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/193?sort=terbaru"
                                      >Buku Soal Ujian UN</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/252?sort=terbaru"
                                      >Soal SMK</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/54?sort=terbaru"
                                  ><u><b>Novel </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/387?sort=terbaru"
                                      >Novel Remaja</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/389?sort=terbaru"
                                      >Novel Romance</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/391?sort=terbaru"
                                      >Novel Horor</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/393?sort=terbaru"
                                      >Terjemahan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/395?sort=terbaru"
                                      >Lainnya</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/55?sort=terbaru"
                                  ><u><b>Buku Anak </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/67?sort=terbaru"
                                      >Buku Seri</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/69?sort=terbaru"
                                      >Creativity Series</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/73?sort=terbaru"
                                      >Cerita Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/74?sort=terbaru"
                                      >Edukasi Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/75?sort=terbaru"
                                      >Buku Anak Lainnya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/77?sort=terbaru"
                                      >Membaca Dan Menulis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/100?sort=terbaru"
                                      >Cerita Rakyat Nusantara</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/101?sort=terbaru"
                                      >Creative Writing</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/117?sort=terbaru"
                                      >Keterampilan Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/148?sort=terbaru"
                                      >Pendidikan Umum</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/154?sort=terbaru"
                                      >Majalah Bravo!</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/156?sort=terbaru"
                                      >Menggambar Dan Mewarnai</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/158?sort=terbaru"
                                      >Paket Buku Spesial</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/196?sort=terbaru"
                                      >Ensiklopedia</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/76?sort=terbaru"
                                  ><u><b>Mainan & Hobi </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/349?sort=terbaru"
                                      >Perlengkapan Menggambar</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/373?sort=terbaru"
                                      >Puzzle</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/375?sort=terbaru"
                                      >Diecast & Model Kit</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/377?sort=terbaru"
                                      >Figur</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/150?sort=terbaru"
                                  ><u><b>Gaya Hidup </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/151?sort=terbaru"
                                      >Tas</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/367?sort=terbaru"
                                      >Sepatu</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/369?sort=terbaru"
                                      >Tumbler</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/251?sort=terbaru"
                                  ><u><b>Buku Import </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/286?sort=terbaru"
                                      >Fiction</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/288?sort=terbaru"
                                  ><u><b>Buku Murah </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/343?sort=terbaru"
                                  ><u><b> ATK</b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/345?sort=terbaru"
                                      >Alat dan buku tulis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/347?sort=terbaru"
                                      >Perlengkapan Kantor</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/353?sort=terbaru"
                                      >IT Supply</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/449?sort=terbaru"
                                  ><u><b>Paket Push </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/451?sort=terbaru"
                                  ><u><b>Buku Flat </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/499?sort=terbaru"
                                  ><u><b>Off. Merchandise </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/501?sort=terbaru"
                                      >Local Merch</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/513?sort=terbaru"
                                  ><u><b>Eureka Mart </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/509?sort=terbaru"
                                      >Kebutuhan Rumah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/515?sort=terbaru"
                                      >Perawatan Kesehatan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/527?sort=terbaru"
                                      >Paling Dicari</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/529?sort=terbaru"
                                  ><u><b>Action Figure </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/541?sort=terbaru"
                                  ><u><b>eBook </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/555?sort=terbaru"
                                      >Majalah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/559?sort=terbaru"
                                      >Buku Sekolah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/561?sort=terbaru"
                                      >Buku Umum</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/549?sort=terbaru"
                                  ><u><b>DIY </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9 col-md-9" id="content_side">
                  <div class="bg0 p-all-15 p-all-0-sm">
                    <div id="postList" class="flex-w">
                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/kartu-angka--huruf-hijaiah-erlangga-efk-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005020.jpg"
                                alt="kartu-angka-dan-huruf-hijaiah"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/kartu-angka--huruf-hijaiah-erlangga-efk-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Kartu Angka & Huruf Hijaiah
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.65.000</small></s
                                ><strong class="cl13"> Rp.52.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/kartu-angka-erlangga-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005000.jpg"
                                alt="kartu-angka"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/kartu-angka-erlangga-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Kartu Angka
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.65.000</small></s
                                ><strong class="cl13"> Rp.52.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/kartu-bentuk--warna-erlangga-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/2014005040.jpg"
                                alt="kartu-bentuk-dan-warna"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/kartu-bentuk--warna-erlangga-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Kartu Bentuk & Warna
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.65.000</small></s
                                ><strong class="cl13"> Rp.52.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/ips-smp-mts-kls7-km-erlangga-eurekabookhouse.co.id-N. Suparno-T.D. Haryo Tamtomo"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0033000640.jpg"
                                alt="ips-smp-mts-kls-7-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/ips-smp-mts-kls7-km-erlangga-eurekabookhouse.co.id-N. Suparno-T.D. Haryo Tamtomo"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Ips Smp/Mts Kls.7/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.98.000</small></s
                                ><strong class="cl13"> Rp.78.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/ips-smp-klsviii-k13n-revisi, erlangga, eurekabookhouse.co.id, T.D. Haryo Tamtomo-N. Suparno"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/81-33-077-2.jpg"
                                alt="ips-smp-kls-viii-k13n-revisi"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/ips-smp-klsviii-k13n-revisi, erlangga, eurekabookhouse.co.id, T.D. Haryo Tamtomo-N. Suparno"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Ips Smp Kls.Viii/K13N/Revisi
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.111.000</small></s
                                ><strong class="cl13"> Rp.88.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-sd-kls4-km-merdeka-erlangga-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970440.jpg"
                                alt="pend-agama-islam-dan-budi-pekerti-sd-kls-4-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-sd-kls4-km-merdeka-erlangga-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Pend. Agama Islam & Budi Pekerti Sd Kls.4/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.62.000</small></s
                                ><strong class="cl13"> Rp.49.600</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/grow-with-english-4-km-merdeka-online-erlangga-eurekabookhouse.co.id-mukarto"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0024200350.jpg"
                                alt="grow-with-english-4-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/grow-with-english-4-km-merdeka-online-erlangga-eurekabookhouse.co.id-mukarto"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Grow With English 4/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.91.000</small></s
                                ><strong class="cl13"> Rp.72.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls4-km-merdeka-erlangga-dwi-tyas-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023230160.jpg"
                                alt="esps-pendidikan-pancasila-sd-mi-kls-4-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls4-km-merdeka-erlangga-dwi-tyas-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Esps Pendidikan Pancasila Sd/Mi Kls.4/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.79.000</small></s
                                ><strong class="cl13"> Rp.63.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls1-km-merdeka-erlangga-buku-online-eurekabookhouse.co.id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0023230130.jpg"
                                alt="esps-pendidikan-pancasila-sd-mi-kls-1-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/esps-pendidikan-pancasila-sd-mi-kls1-km-merdeka-erlangga-buku-online-eurekabookhouse.co.id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Esps Pendidikan Pancasila Sd/Mi Kls.1/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.76.000</small></s
                                ><strong class="cl13"> Rp.60.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/esps-matematika-sd-mi-kls1-km-merdeka"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025100700.jpg"
                                alt="esps-matematika-sd-mi-kls-1-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/esps-matematika-sd-mi-kls1-km-merdeka"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Esps Matematika Sd/Mi Kls.1/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.88.000</small></s
                                ><strong class="cl13"> Rp.70.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/esps-ipas-sd-mi-kls1-km"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0025000960.jpg"
                                alt="esps-ipas-sd-mi-kls-1-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/esps-ipas-sd-mi-kls1-km"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Esps Ipas Sd/Mi Kls.1/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.88.000</small></s
                                ><strong class="cl13"> Rp.70.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/pend-jasmani-orkes-smp-mts-kls7-km"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0036130190.jpg"
                                alt="pend-jasmani-orkes-smp-mts-kls-7-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/pend-jasmani-orkes-smp-mts-kls7-km"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Pend. Jasmani Orkes Smp/Mts Kls.7/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.97.000</small></s
                                ><strong class="cl13"> Rp.77.600</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-smp-kls7-km"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0032970470.jpg"
                                alt="pend-agama-islam-dan-budi-pekerti-smp-kls-7-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/pend-agama-islam--budi-pekerti-smp-kls7-km"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Pend. Agama Islam & Budi Pekerti Smp Kls.7/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.81.000</small></s
                                ><strong class="cl13"> Rp.64.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/informatika-smp-mts-klsvii-km"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0030040040.jpg"
                                alt="informatika-smp-mts-kls-vii-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/informatika-smp-mts-klsvii-km"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Informatika Smp/Mts Kls.Vii/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.98.000</small></s
                                ><strong class="cl13"> Rp.78.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bright-1-an-english-course-for-smp-mts-km"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0034200080.jpg"
                                alt="bright-1-an-english-course-for-smp-mts-km"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bright-1-an-english-course-for-smp-mts-km"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bright 1 An English Course For Smp/Mts/Km
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.58.000</small></s
                                ><strong class="cl13"> Rp.46.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl4-klsvi-k2019-agama-islam, madrasah-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970161.jpg"
                                alt="bina-sejarah-keb-islam-jl-4-kls-vi-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl4-klsvi-k2019-agama-islam, madrasah-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Sejarah Keb. Islam Jl.4 Kls.Vi/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.53.000</small></s
                                ><strong class="cl13"> Rp.42.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl3-kls-v-k2019-islam-madrasah-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970151.jpg"
                                alt="bina-sejarah-keb-islam-jl-3-kls-v-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl3-kls-v-k2019-islam-madrasah-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Sejarah Keb. Islam Jl.3 Kls V/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.59.000</small></s
                                ><strong class="cl13"> Rp.47.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl1-klsiii-k2019-agama-islam, pesantren, madrasah"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970131.jpg"
                                alt="bina-sejarah-keb-islam-jl-1-kls-iii-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-sejarah-keb-islam-jl1-klsiii-k2019-agama-islam, pesantren, madrasah"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Sejarah Keb. Islam Jl.1 Kls.Iii/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.64.000</small></s
                                ><strong class="cl13"> Rp.51.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls6-k2019-islam-agama-madrasah-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970151.jpg"
                                alt="bina-fiqih-mi-kls-6-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls6-k2019-islam-agama-madrasah-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Fiqih Mi Kls.6/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.49.000</small></s
                                ><strong class="cl13"> Rp.39.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls5-k2019-madrasah-islam-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970141.jpg"
                                alt="bina-fiqih-mi-kls-5-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls5-k2019-madrasah-islam-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Fiqih Mi Kls.5/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.51.000</small></s
                                ><strong class="cl13"> Rp.40.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls3-k2019-islam-agama-madrasah-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970121.jpg"
                                alt="bina-fiqih-mi-kls-3-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls3-k2019-islam-agama-madrasah-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Fiqih Mi Kls.3/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.81.000</small></s
                                ><strong class="cl13"> Rp.64.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls2-k2019-agama-islam-madrasah-pesantren"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0022970111.jpg"
                                alt="bina-fiqih-mi-kls-2-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-fiqih-mi-kls2-k2019-agama-islam-madrasah-pesantren"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Fiqih Mi Kls.2/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.51.000</small></s
                                ><strong class="cl13"> Rp.40.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls6-k2019-agama-islam-madrasah"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970121.jpg"
                                alt="bina-belajar-al-quran-dan-hadis-mi-kls-6-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls6-k2019-agama-islam-madrasah"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Belajar Al-Quran & Hadis Mi Kls.6/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.51.000</small></s
                                ><strong class="cl13"> Rp.40.800</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls5-k2019-madrasah-agama-islam"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0092970111.jpg"
                                alt="bina-belajar-al-quran-dan-hadis-mi-kls-5-k2019"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/bina-belajar-al-quran--hadis-mi-kls5-k2019-madrasah-agama-islam"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Bina Belajar Al-Quran & Hadis Mi Kls.5/K2019
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.57.000</small></s
                                ><strong class="cl13"> Rp.45.600</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
export default {
  name: "erlanggaPage",
};
</script>
    <style type="text/css">
.block-produk {
  width: 25%;
  margin-bottom: 15px;
}
.block-produk .kartu {
  margin-left: 10px;
  margin-right: 10px;
}
.block-produk .kartu:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}
</style>
