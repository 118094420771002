<template>
  <div>
    <section class="bg6">
      <div class="container">
        <div class="card-atas mr-5" style="margin-top: 20px">
          <div
            class="d-lg-none w-full d-flex justify-content-between"
            style="padding-left: 10px; padding-top: 25px"
          >
            <div>
              <span
                class="d-flex justify-content-start"
                style="
                  color: white;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                "
                >{{ judulData }}</span
              >
              <p
                style="
                  color: var(--neutral-02, #efefef);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 15.4px; /* 110% */
                  letter-spacing: -0.14px;
                "
              >
                Temukan {{ judulData }} favoritmu!
              </p>
            </div>
            <div></div>
            <div style="margin-right: 10px; margin-bottom: 20px">
              <b-button
                style="
                  background-color: white;
                  color: #244786;
                  border: 1px solid #244786;
                "
                class="btn-filter-respon"
                @click="modalShow = !modalShow"
                >Filter
                <i
                  style="padding-left: 10px"
                  class="fa fa-chevron-down"
                  aria-hidden="true"
                ></i>
              </b-button>
            </div>
          </div>
          <div class="row mr-5">
            <div
              class="d-xl-block d-lg-block d-md-block d-sm-none d-none mr-5"
              style="padding-left: 10px; padding-top: 25px"
            >
              <span
                class="d-flex justify-content-start"
                style="
                  color: white;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px;
                  letter-spacing: -0.16px;
                "
                >{{ judulData }}</span
              >
              <p
                style="
                  color: var(--neutral-02, #efefef);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 15.4px; /* 110% */
                  letter-spacing: -0.14px;
                "
              >
                Temukan {{ judulData }} favoritmu disini!
              </p>
            </div>
          </div>
        </div>

        <div class="mt-3" style="padding: 0px">
          <div class="row" style="padding: 0px">
            <div
              class="d-xl-block d-lg-block d-md-block d-sm-none d-none col-12 col-md-12 col-lg-3 mt-4"
            >
              <b-card style="border-radius: 15px">
                <div class="mt-2"></div>

                <div v-if="categoryAtas && categoryAtas.length > 0">
                  <div class="d-flex justify-content-between">
                    <div class="mt-2">
                      <span
                        style="
                          color: #000;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 600;
                          text-align: left;
                          line-height: 18px; /* 112.5% */
                          letter-spacing: -0.16px;
                        "
                        >Kategori Terkait
                      </span>
                    </div>
                    <div>
                      <span style="color: white">Ka</span>
                    </div>
                    <div>
                      <b-button
                        :class="visible ? null : 'collapsed'"
                        :aria-expanded="visible ? 'true' : 'false'"
                        aria-controls="collapse-4"
                        @click="visible = !visible"
                        style="background-color: white; border: none"
                      >
                        <span
                          style="
                            color: var(--primary-04, #3860a8);
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 13.2px; /* 110% */
                            letter-spacing: -0.12px;
                          "
                        >
                          Lihat semua
                          <i
                            class="fa fa-chevron-circle-down"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </b-button>
                    </div>
                  </div>
                  <div>
                    <div>
                      <b-collapse
                        id="collapse-4"
                        v-model="visible"
                        class="mt-2"
                      >
                        <b-card style="width: 100%; border: none">
                          <div class="d-flex flex-wrap justify-content-center">
                            <button
                              v-for="category in categoryAtas"
                              :key="category.id"
                              class="btn-kat"
                              @click="fetchApiData(category.slug)"
                            >
                              <span
                                style="
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px; /* 110% */
                                  letter-spacing: -0.12px;
                                  color: #244786;
                                "
                                >{{ category.name }}</span
                              >
                            </button>
                          </div>
                        </b-card>
                      </b-collapse>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="d-flex justify-content-between">
                  <div class="mt-2">
                    <span class="text-filter">Berdasarkan Kategori</span>
                  </div>
                </div>
                <div class="mt-3 accordion" role="tablist">
                  <b-card
                    v-for="(category, index) in categories"
                    :key="index"
                    no-body
                    class="mb-1"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        v-b-toggle="`accordion-${category.category_id}`"
                        style="
                          background-color: white;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          width: 95%;
                        "
                      >
                        <span
                          @click="fetchApiData(category.slug)"
                          style="color: var(--primary-05, #244786)"
                          >{{ category.name }}</span
                        >
                        <i
                          style="color: var(--primary-05, #244786)"
                          class="fa fa-chevron-down"
                          aria-hidden="true"
                        ></i>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="`accordion-${category.category_id}`"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <!-- Loading skeleton -->
                        <div v-if="isLoading" class="loading-skeleton"></div>

                        <!-- Data kategori -->
                        <ul
                          v-if="!isLoading && category.subcategories.length > 0"
                        >
                          <li
                            v-for="(
                              subCategory, subIndex
                            ) in category.subcategories"
                            :key="subIndex"
                            class="list-item"
                            style="text-align: left"
                          >
                            <b-button
                              v-b-toggle="
                                `sub-accordion-${subCategory.category_id}`
                              "
                              style="
                                background-color: white;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 95%;
                                color: #244786;
                              "
                              class="mt-2"
                            >
                              <span @click="fetchApiData(subCategory.slug)">
                                {{ subCategory.name }}</span
                              >
                              <i
                                style="color: var(--primary-05, #244786)"
                                class="fa fa-chevron-down"
                                aria-hidden="true"
                              ></i>
                            </b-button>
                            <b-collapse
                              :id="`sub-accordion-${subCategory.category_id}`"
                            >
                              <ul
                                style="margin-top: 25px; margin-bottom: 25px"
                                v-if="subCategory.subcategories.length > 0"
                              >
                                <li
                                  v-for="(
                                    nestedCategory, nestedIndex
                                  ) in subCategory.subcategories"
                                  :key="nestedIndex"
                                  class="mt-2"
                                  style="
                                    text-align: left;
                                    background-color: white !important;
                                  "
                                >
                                  <span
                                    @click="fetchApiData(nestedCategory.slug)"
                                    style="color: black; margin-left: 20px"
                                    class="mr-3"
                                  >
                                    <b> > </b> {{ nestedCategory.name }}
                                  </span>
                                </li>
                              </ul>
                            </b-collapse>
                          </li>
                        </ul>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <hr />
                <div class="mt-3 d-flex justify-content-between">
                  <div class="mt-2">
                    <p class="text-filter">
                      Berdasarkan Penerbit
                      <span style="color: white">hahahaahssaaha</span>
                    </p>
                    <v-select
                      v-model="selectedManufacturers"
                      :options="dataToko"
                      label="name"
                      value="slug"
                      placeholder="Pilih Brand"
                      multiple
                    ></v-select>
                  </div>
                </div>
                <hr />
                <div class="mt-3 d-flex justify-content-between">
                  <div class="mt-2">
                    <p class="text-filter">
                      Berdasarkan Penulis
                      <span style="color: white">hahahaahaaha</span>
                    </p>
                    <v-select
                      v-model="selectedAuthors"
                      :options="dataPenulis"
                      label="nama_author"
                      value="slug_author"
                      placeholder="Pilih Penulis"
                      multiple
                      style="width: 100% !important"
                    ></v-select>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between">
                  <div class="mt-2">
                    <p class="text-filter">
                      Berdasarkan Diskon
                      <span style="color: white">hahahaahaaha</span>
                    </p>
                    <v-select
                      v-model="selectedDiskon"
                      :options="dataDiskon"
                      label="name"
                      value="slug"
                      placeholder="Pilih Diskon"
                      multiple
                    ></v-select>
                  </div>
                </div>
                <hr />
                <div class="form-group">
                  <p class="text-filter">Berdasarkan Harga</p>
                  <div class="mt-3 row" style="padding: 0">
                    <div class="col-lg-6">
                      <input
                        type="number"
                        v-model="minPrice"
                        class="form-control"
                        placeholder="Min Price"
                      />
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="number"
                        v-model="maxPrice"
                        class="form-control"
                        placeholder="Max Price"
                      />
                    </div>
                  </div>
                </div>

                <b-button
                  style="
                    width: 100%;
                    background-color: #244786;
                    color: white;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-left: 0px;
                  "
                  @click="filterResults"
                  >Filter</b-button
                >
                <b-button
                  style="
                    width: 100%;
                    background-color: white;
                    outline: solid 1px #244786;
                    color: #244786;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-left: 0px;
                  "
                  class="mt-2"
                  @click="clearFilters"
                  >Clear Filter</b-button
                >
              </b-card>
            </div>
            <div class="col-12 col-md-12 col-lg-9 mt-4">
              <div class="d-lg-none w-full d-flex flex-row-reverse">
                <div class="pb-4">
                  <div>
                    <b-modal
                      v-model="modalShow"
                      hide-footer
                      title="Filter Kategori"
                      size="lg"
                    >
                      <div class="" style="height: 800px">
                        <div v-if="categoryAtas && categoryAtas.length > 0">
                          <div class="d-flex justify-content-between">
                            <div class="mt-2">
                              <span
                                style="
                                  color: #000;
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  text-align: left;
                                  line-height: 18px; /* 112.5% */
                                  letter-spacing: -0.16px;
                                "
                                >Kategori Terkait
                              </span>
                            </div>
                            <div>
                              <span style="color: white">Ka</span>
                            </div>
                            <div>
                              <b-button
                                :class="visible ? null : 'collapsed'"
                                :aria-expanded="visible ? 'true' : 'false'"
                                aria-controls="collapse-4"
                                @click="visible = !visible"
                                style="background-color: white; border: none"
                              >
                                <span
                                  style="
                                    color: var(--primary-04, #3860a8);
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 13.2px; /* 110% */
                                    letter-spacing: -0.12px;
                                  "
                                >
                                  Lihat semua
                                  <i
                                    class="fa fa-chevron-circle-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </b-button>
                            </div>
                          </div>
                          <div>
                            <div>
                              <b-collapse
                                id="collapse-4"
                                v-model="visible"
                                class="mt-2"
                              >
                                <b-card style="width: 100%; border: none">
                                  <div
                                    class="d-flex flex-wrap justify-content-center"
                                  >
                                    <button
                                      v-for="category in categoryAtas"
                                      :key="category.id"
                                      class="btn-kat"
                                      @click="fetchApiData(category.slug)"
                                    >
                                      <span
                                        style="
                                          font-size: 12px;
                                          font-style: normal;
                                          font-weight: 400;
                                          line-height: 13.2px; /* 110% */
                                          letter-spacing: -0.12px;
                                        "
                                        >{{ category.name }}</span
                                      >
                                    </button>
                                  </div>
                                </b-card>
                              </b-collapse>
                            </div>
                          </div>
                          <hr />
                        </div>

                        <div class="d-flex justify-content-between">
                          <div class="mt-2">
                            <span class="text-filter"
                              >Berdasarkan Kategori</span
                            >
                          </div>
                        </div>
                        <div class="mt-3 accordion" role="tablist">
                          <b-card
                            v-for="(category, index) in categories"
                            :key="index"
                            no-body
                            class="mb-1"
                          >
                            <b-card-header
                              header-tag="header"
                              class="p-1"
                              role="tab"
                            >
                              <b-button
                                v-b-toggle="`accordion-${category.category_id}`"
                                style="
                                  background-color: white;
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  width: 100%;
                                "
                              >
                                <span
                                  @click="fetchApiData(category.slug)"
                                  style="color: var(--primary-05, #244786)"
                                  >{{ category.name }}</span
                                >
                                <i
                                  style="color: var(--primary-05, #244786)"
                                  class="fa fa-chevron-down"
                                  aria-hidden="true"
                                ></i>
                              </b-button>
                            </b-card-header>
                            <b-collapse
                              :id="`accordion-${category.category_id}`"
                              accordion="my-accordion"
                              role="tabpanel"
                            >
                              <b-card-body>
                                <!-- Loading skeleton -->
                                <div
                                  v-if="isLoading"
                                  class="loading-skeleton"
                                ></div>

                                <!-- Data kategori -->
                                <ul
                                  v-if="
                                    !isLoading &&
                                    category.subcategories.length > 0
                                  "
                                >
                                  <li
                                    v-for="(
                                      subCategory, subIndex
                                    ) in category.subcategories"
                                    :key="subIndex"
                                    class="list-item"
                                    style="text-align: left"
                                  >
                                    <b-button
                                      v-b-toggle="
                                        `sub-accordion-${subCategory.category_id}`
                                      "
                                      style="
                                        background-color: white;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 100%;
                                        color: #244786;
                                      "
                                      class="mt-2"
                                    >
                                      <span
                                        @click="fetchApiData(subCategory.slug)"
                                      >
                                        {{ subCategory.name }}</span
                                      >
                                      <i
                                        style="
                                          color: var(--primary-05, #244786);
                                        "
                                        class="fa fa-chevron-down"
                                        aria-hidden="true"
                                      ></i>
                                    </b-button>
                                    <b-collapse
                                      :id="`sub-accordion-${subCategory.category_id}`"
                                    >
                                      <ul
                                        style="
                                          margin-top: 25px;
                                          margin-bottom: 25px;
                                        "
                                        v-if="
                                          subCategory.subcategories.length > 0
                                        "
                                      >
                                        <li
                                          v-for="(
                                            nestedCategory, nestedIndex
                                          ) in subCategory.subcategories"
                                          :key="nestedIndex"
                                          class="mt-2"
                                          style="
                                            text-align: left;
                                            background-color: white !important;
                                          "
                                        >
                                          <span
                                            @click="
                                              fetchApiData(nestedCategory.slug)
                                            "
                                            style="
                                              color: black;
                                              margin-left: 20px;
                                            "
                                          >
                                            <b> > </b> {{ nestedCategory.name }}
                                          </span>
                                        </li>
                                      </ul>
                                    </b-collapse>
                                  </li>
                                </ul>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                        <hr />
                        <div class="mt-3" style="width: 100%">
                          <div class="mt-2">
                            <p class="text-filter">Berdasarkan Penerbit</p>
                            <v-select
                              v-model="selectedManufacturers"
                              :options="dataToko"
                              label="name"
                              value="slug"
                              placeholder="Pilih Brand"
                              multiple
                            ></v-select>
                          </div>
                        </div>
                        <hr />
                        <div class="mt-3">
                          <div class="mt-2">
                            <p class="text-filter">Berdasarkan Penulis</p>
                            <v-select
                              v-model="selectedAuthors"
                              :options="dataPenulis"
                              label="nama_author"
                              value="slug_author"
                              placeholder="Pilih Penulis"
                              multiple
                              style="width: 100% !important"
                            ></v-select>
                          </div>
                        </div>
                        <hr />
                        <div class="">
                          <div class="mt-2">
                            <p class="text-filter">Berdasarkan Diskon</p>
                            <v-select
                              v-model="selectedDiskon"
                              :options="dataDiskon"
                              label="name"
                              value="slug"
                              placeholder="Pilih Diskon"
                              multiple
                              style="width: 100% !important"
                            ></v-select>
                          </div>
                        </div>
                        <hr />
                        <div class="form-group">
                          <p class="text-filter">Berdasarkan Harga</p>
                          <div class="mt-3 row" style="padding: 0">
                            <div class="col-lg-6 col-md-12 mb-2 mb-lg-0">
                              <input
                                type="number"
                                v-model="minPrice"
                                class="form-control"
                                placeholder="Min Price"
                              />
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <input
                                type="number"
                                v-model="maxPrice"
                                class="form-control"
                                placeholder="Max Price"
                              />
                            </div>
                          </div>
                        </div>

                        <b-button
                          style="
                            width: 100%;
                            background-color: #244786;
                            color: white;
                            cursor: pointer;
                            border-radius: 5px;
                            margin-left: 0px;
                          "
                          @click="filterResults"
                          >Filter</b-button
                        >
                        <b-button
                          style="
                            width: 100%;
                            background-color: white;
                            outline: solid 1px #244786;
                            color: #244786;
                            cursor: pointer;
                            border-radius: 5px;
                            margin-left: 0px;
                          "
                          class="mt-2"
                          @click="clearFilters"
                          >Clear Filter</b-button
                        >
                        <br />
                        <br />
                      </div>
                    </b-modal>
                  </div>
                </div>
              </div>

              <div
                style="padding-top: 60px; padding-bottom: 60px"
                v-if="isLoading"
              >
                <img
                  src="../assets/images/magnifier.png"
                  alt="No Data Image"
                  style="max-width: 300px; height: auto"
                />
                <p
                  class="mt-5"
                  style="
                    color: #000;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.2px;
                  "
                >
                  Mohon tunggu data sedang di proses...
                </p>
              </div>

              <!-- Check if categoryData is empty -->
              <div
                style="padding-top: 40px; padding-bottom: 40px"
                v-else-if="categoryData.length === 0"
              >
                <img
                  src="../assets/images/notfound.png"
                  alt="No Data Image"
                  style="max-width: 300px; height: auto"
                />
                <p
                  class="mt-5"
                  style="
                    color: #000;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.2px;
                  "
                >
                  Ups, produk yang anda cari kosong ...
                </p>
              </div>
              <div v-else>
                <div class="row" style="padding: 0">
                  <div
                    @click="handleProductClick(item)"
                    class="col-6 col-sm-6 col-md-4 col-lg-3"
                    v-for="item in getPaginatedItems()"
                    :key="item.product_id"
                  >
                    <div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
                          border-radius: 15px;
                          height: 260px;
                          background-color: white;
                        "
                      >
                        <div
                          class="image-container"
                          style="
                            align-items: center;
                            width: 100%;
                            height: 175px;
                          "
                        >
                          <!-- Kasus pertama: item.image tersedia -->
                          <img
                            v-if="item.image && item.image.endsWith('.jpg')"
                            :src="
                              'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                              item.image
                            "
                            class="gambar-produk"
                            :style="{
                              'max-width': '100%',
                              height: 'auto',
                              cursor: 'pointer',
                              opacity: item.quantity === 0 ? 0.2 : 1,
                            }"
                            alt="Product Image"
                            @error="handleImageError"
                            @click="
                              item.quantity > 0 && handleProductClick(item)
                            "
                          />

                          <!-- Kasus kedua: item.image tidak tersedia -->
                          <img
                            v-else
                            src="https://cdn.eurekabookhouse.co.id/ebh/product/all/71-59-020-0.jpg"
                            class="gambar-produk"
                            :style="{
                              'max-width': '100%',
                              height: 'auto',
                              cursor: 'pointer',
                              opacity: item.quantity === 0 ? 0.2 : 1,
                            }"
                            alt="Default Product Image"
                            @error="handleImageError"
                            @click="
                              item.quantity > 0 && handleProductClick(item)
                            "
                          />
                          <div
                            v-if="item.quantity === 0"
                            class="sold-out-label"
                            style="
                              position: absolute;
                              top: 30%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              font-weight: 500;
                              padding: 8px;
                              font-size: 20px;
                              color: rgb(119, 119, 119);
                              border-radius: 5px;
                            "
                          >
                            <b>Habis</b>
                          </div>
                        </div>

                        <div class="badge-container" style="text-align: left">
                          <div style="margin-top: -23px">
                            <img
                              style="max-width: 82px; max-height: 25px"
                              src="https://stagingapi.eurekabookhouse.com/assets/image/mall_badge.png"
                              alt="Badge Image"
                            />
                          </div>
                        </div>

                        <div style="padding-bottom: 15px">
                          <p
                            @click="handleProductClick(item)"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              padding: 10px;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                              text-align: left;
                              cursor: pointer;
                            "
                          >
                            {{ truncateText(item.name, 20) }}
                          </p>

                          <div
                            style="
                              padding-left: 8px;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <div
                              v-if="item.diskon > 0"
                              class="discount-label"
                              style="
                                content: attr(data-label);
                                font-size: 15px;
                                font-weight: 700;
                                color: #fff;
                                line-height: 1.5;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                width: 42px;
                                height: 42px;
                                border-radius: 30px;
                                padding: 0 6px;
                                background-color: red;
                                top: 9px;
                                right: 25px;
                                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
                              "
                            >
                              {{ item.diskon }}%
                            </div>
                            <span
                              v-if="item.diskon !== 'tidak tersedia'"
                              style="
                                color: var(--neutral-04, #989898);
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 13.2px;
                                letter-spacing: -0.12px;
                              "
                            >
                              <s>
                                Rp
                                {{
                                  item.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                }}
                              </s>
                            </span>
                          </div>

                          <p
                            style="
                              color: var(--primary-05, #244786);
                              font-size: 16px;
                              padding: 10px;
                              font-style: normal;
                              font-weight: 600;
                              line-height: 2px;
                              text-align: left;
                              letter-spacing: -0.16px;
                            "
                          >
                            Rp
                            {{
                              item.diskon !== "tidak tersedia"
                                ? (
                                    item.price -
                                    item.price * (item.diskon / 100)
                                  )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                : item.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="mt-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  v-if="categoryData.length > 0"
                  class="mt-5 pagination-container"
                >
                  <button
                    @click="
                      scrollToTop();
                      currentPage -= 1;
                    "
                    :disabled="currentPage === 1"
                    class="pagination-button"
                    :class="{ 'pagination-disabled': currentPage === 1 }"
                    :style="{
                      backgroundColor: currentPage === 1 ? '#3860A8' : '',
                    }"
                  >
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                  </button>

                  <!-- Menampilkan beberapa nomor halaman sekitarnya -->
                  <span
                    v-for="pageNumber in visiblePages"
                    :key="pageNumber"
                    @click="
                      scrollToTop();
                      currentPage = pageNumber;
                    "
                    class="pagination-button"
                    :class="{
                      'pagination-disabled': currentPage === pageNumber,
                    }"
                    :style="{
                      backgroundColor:
                        currentPage === pageNumber ? '#FFC341' : '',
                      color: currentPage === pageNumber ? 'black' : '',
                    }"
                  >
                    {{ pageNumber }}
                  </span>

                  <button
                    @click="
                      scrollToTop();
                      currentPage += 1;
                    "
                    :disabled="
                      currentPage ===
                      Math.ceil(categoryData.length / itemsPerPage)
                    "
                    class="pagination-button"
                    :class="{
                      'pagination-disabled':
                        currentPage ===
                        Math.ceil(categoryData.length / itemsPerPage),
                    }"
                    :style="{
                      backgroundColor:
                        currentPage ===
                        Math.ceil(categoryData.length / itemsPerPage)
                          ? '#3860A8'
                          : '',
                    }"
                  >
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />

          <br />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import cardKategoriPage from "@/components/cardKategori.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "kategoriPage",
  components: {
    // cardKategoriPage,
    vSelect,
  },
  data() {
    return {
      judulData: null,
      modalShow: false,
      router: this.$router,
      currentPage: 1,
      itemsPerPage: 20,
      visible: true,
      selectedNestedCategory: null,
      categoryData: [],
      categoryAtas: [],
      categories: [],
      dataPenulis: [],
      dataToko: [],
      dataDiskon: [],
      selectedAuthors: [], // Store selected authors
      selectedManufacturers: [], // Store selected manufacturers
      selectedDiskon: [],
      minPrice: null,
      maxPrice: null,
      isLoading: true,
    };
  },
  mounted() {
    this.fetchData();
    const slug = this.$route.params.slug;
    axios
      // ALAMANDA
      .get(`https://stagingapi.eurekabookhouse.com/category/menu/${slug}`)

      .then((response) => {
        this.isLoading = false;
        this.judulData = response.data.data.selectedSlug;
        this.categoryData = response.data.data.order[0];
        // console.log("data1", this.categoryData);
        this.categoryAtas = response.data.data.relateCategory;
        // console.log("data2", this.categoryAtas);
        this.dataPenulis = response.data.data.dataAuthor;
        // console.log("penulis", this.dataPenulis);
        this.dataToko = response.data.data.dataManufacturer;
        // console.log("Toko", this.dataToko);
        this.dataDiskon = response.data.data.dataDiscount;
        // console.log("diskon", this.dataDiskon);
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
    this.scrollToTop();
  },
  computed: {
    visiblePages() {
      return this.getVisiblePages();
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0, // Atur posisi scroll ke bagian atas halaman
        behavior: "smooth", // Efek smooth scrolling
      });
    },
    filterResults() {
      const selectedAuthors = this.selectedAuthors
        .map((author) => author.slug_author)
        .join(",");
      const selectedManufacturers = this.selectedManufacturers
        .map((manufacturer) => manufacturer.slug)
        .join(",");
      const selectedDiskon = this.selectedDiskon.join(",");
      const slug = this.$route.params.slug; // Assuming slug is available in your component
      const minPrice = this.minPrice || "";
      const maxPrice = this.maxPrice || "";

      // Update URL with query parameters
      this.$router.push({
        path: `/kategori/${slug}`,
        query: {
          author: selectedAuthors,
          discount: selectedDiskon,
          manufacturer: selectedManufacturers,
          min_price: minPrice,
          max_price: maxPrice,
        },
      });

      // Perform API call (optional)
      this.performApiCall();
      this.modalShow = false;
      this.scrollToTop();
    },
    clearFilters() {
      // Reset filter-related data properties
      this.selectedAuthors = [];
      this.selectedManufacturers = [];
      this.selectedDiskon = [];
      this.minPrice = null;
      this.maxPrice = null;

      // Clear URL parameters by navigating to the original route without query parameters
      const slug = this.$route.params.slug;
      this.$router.push(`/kategori/${slug}`);

      // Perform API call to reset data to default (optional)
      this.performApiCall();
      this.modalShow = false;
      this.scrollToTop();
    },
    performApiCall() {
      let apiUrl = `https://stagingapi.eurekabookhouse.com/category/menu/${this.$route.params.slug}?`;

      // Check if selectedAuthors is not empty
      if (this.selectedAuthors.length > 0) {
        const selectedAuthors = this.selectedAuthors
          .map((author) => author.slug_author)
          .join(",");
        apiUrl += `author=${selectedAuthors}&`;
      }

      // Check if selectedManufacturers is not empty
      if (this.selectedManufacturers.length > 0) {
        const selectedManufacturers = this.selectedManufacturers
          .map((manufacturer) => manufacturer.slug)
          .join(",");
        apiUrl += `manufacturer=${selectedManufacturers}&`;
      }

      // Check if selectedDiskon is not empty
      if (this.selectedDiskon.length > 0) {
        const selectedDiskon = this.selectedDiskon.join(",");
        apiUrl += `discount=${selectedDiskon}&`;
      }

      // Add minPrice and maxPrice only if they are provided
      if (
        this.minPrice !== undefined &&
        this.minPrice !== null &&
        this.minPrice !== ""
      ) {
        apiUrl += `min_price=${this.minPrice}&`;
      }

      if (
        this.maxPrice !== undefined &&
        this.maxPrice !== null &&
        this.maxPrice !== ""
      ) {
        apiUrl += `max_price=${this.maxPrice}`;
      }

      axios
        .get(apiUrl)
        .then((response) => {
          // Update your data properties based on the API response
          this.judulData = response.data.data.selectedSlug;
          this.categoryData = response.data.data.order[0];
          this.categoryAtas = response.data.data.relateCategory;
          this.dataPenulis = response.data.data.dataAuthor;
          this.dataToko = response.data.data.dataManufacturer;
          this.dataDiskon = response.data.data.dataDiscount;
        })
        .catch((error) => {
          console.error(error);
        });
      this.scrollToTop();
    },

    handleProductClick(item) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", item.product_id);

      // Navigate to the detail page
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: item.slug },
      });
      this.scrollToTop();
    },
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + "...";
      }
      return text;
    },
    getVisiblePages() {
      const totalPages = Math.ceil(
        this.categoryData.length / this.itemsPerPage
      );
      const maxVisiblePages = 3; // Jumlah maksimum nomor halaman yang ditampilkan

      let startPage = Math.max(
        1,
        this.currentPage - Math.floor(maxVisiblePages / 2)
      );
      let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

      // Pastikan tidak ada nomor halaman yang kurang dari 1
      if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      );
    },
    getPaginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.categoryData.slice(startIndex, endIndex);
    },
    handleImageError(event) {
      // Ubah gambar menjadi default jika terjadi kesalahan
      event.target.src =
        "https://cdn.eurekabookhouse.co.id/ebh/product/all/cover-buku.jpg";
    },
    async fetchApiData(slug) {
      try {
        const response = await fetch(
          `https://stagingapi.eurekabookhouse.com/category/menu/${slug}`
        );
        const data = await response.json();
        this.categoryData = data.data.order[0];
        // console.log("ini data kategoriii awal", this.categoryData);
        // Navigasi ke halaman baru dengan slug
        this.router.push(`/kategori/${slug}`);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      this.scrollToTop();
    },
    selectNestedCategory(nestedCategory) {
      this.selectedNestedCategory = nestedCategory;
      this.scrollToTop();
      // Add any additional logic you need when a nested category is selected
    },
    fetchData() {
      fetch("https://stagingapi.eurekabookhouse.com/category/sub")
        .then((response) => response.json())
        .then((data) => {
          // Assuming the API response is stored in the "data" variable
          this.categories = data.data.order;
        })
        .catch((error) => console.error("Error fetching data:", error));
      this.scrollToTop();
    },
    redirectToDetail(productId, slug) {
      // Navigate to the detail page using Vue Router
      this.$router.push(`/detailproduk/${slug}/${productId}`);
      this.scrollToTop();
    },
  },
};
</script>

<style type="text/css">
.btn-filter-respon:hover {
  background-color: #244786;
  color: white;
}
.btn-filter-respon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #244786;
}

/* .dropdown-menu {
  max-height: 100%;
  overflow-y: auto; 
} */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); /* Semi-transparent white background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a high z-index to ensure it's above other elements */
}
.gambar-produk {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  margin: auto;
  border-radius: 10px 10px 0px 0px;
}
.text-filter {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  line-height: 18px;
  letter-spacing: -0.16px;
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.pagination-button {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #244786;
  border-radius: 4px;
  cursor: pointer;
  background-color: #244786;
  color: white;
}

.pagination-button:hover {
  background-color: #5178be;
  color: black;
}

.pagination-disabled {
  color: #ccc;
  cursor: not-allowed;
}
.btn-kat-active {
  padding: 10px;
  margin-right: 5px;
  gap: 10px;
  border-radius: 10px;
  background: var(--primary-05, #244786);
}
.btn-kat {
  display: flex;
  padding: 10px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--neutral-03, #cbcbcb);
  background: var(--neutral-01, #fefefe);
  transition: background-color 0.3s ease; /* Adding transition for a smooth effect */
}

/* .btn-kat:hover {
  background: #244786; 
  color: white;
} */

.card-prdct {
  /* width: 100%; */
  border-radius: 15px;
  margin-right: 10px;
  margin-top: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  margin-right: 5px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem;
}

.button-co {
  display: flex;
  height: 42px;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  background: var(--primary-04, #3860a8);
}
.button-voucher {
  display: flex;
  height: 42px;
  width: 100%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  background: var(--primary-01, #d2e2ff);
}
.full-width-button {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  background: var(--primary-01, #d2e2ff);
  border: none; /* Hapus border tombol jika tidak diperlukan */
}
.card-atas {
  width: 100%;
  border-radius: 10px;
  height: 72px;
  background-repeat: no-repeat;
  background-size: cover; /* Atur sesuai kebutuhan Anda: contain atau cover */
  flex-shrink: 0;
  background-image: url("../assets/images/kategori/ha.png"); /* Gambar sebagai latar belakang */
}
.cart-card1 {
  display: flex;
  /* width: 775px; */
  height: 40px;
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 10px 10px 0px 0px;
  background: var(--neutral-01, #fefefe);
}
.cart-card2 {
  display: flex;
  /* width: 775px; */
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0px 0px 0px 0px;
  background: var(--neutral-01, #fefefe);
}
.cart-card3 {
  display: flex;
  /* width: 775px; */
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0px 0px 10px 10px;
  background: var(--neutral-01, #fefefe);
}
.cart-card {
  /* display: flex; */
  padding: 9px;
  align-items: flex-start;
  border-radius: 10px;
  background: var(--neutral-01, #fefefe);
}
.cart-judul {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  background: var(--neutral-01, #fefefe);
}
.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
}
.text1l {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  min-width: 1px;
}
td:not(:last-child) {
  white-space: nowrap;
  width: 1px;
}
.transfer-group-table {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  min-width: 1px;
}
/*	.img-produk-cart{width: 20%;}*/
.img-produk-cart img {
  width: 50px;
}
.mobile-nav {
  display: none;
}
.js-del-cart {
  height: 30px;
  padding: 0.5rem;
  line-height: 1;
}

.table td,
.table th {
  /* vertical-align: left; */
}
.drpdown {
  background-color: #6d9aec;
  border-radius: 0px 0px 10px 10px;
  border: none;
}
.list-item:hover {
  cursor: pointer; /* Mengubah kursor saat dihover */
  color: white;
  width: 100%;
}
</style>
