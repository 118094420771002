<template>
  <div>
    <section class="bg6 p-t-10 p-b-10">
      <form id="form-cart" class="p-b-85">
        <div v-if="loading" class="loading-overlay">
          <img src="../../assets/images/login/loader.gif" style="width: 10%" />
          <p style="font-size: 20px; font-weight: 500">Loading...</p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-xl-3 m-lr-auto m-b-50 d-none d-lg-block">
              <sidebarPage />
            </div>

            <div class="col-lg-10 col-xl-9 p-lr-0-sm text-left mt-4">
              <div class="row">
                <button
                  v-for="button in buttons"
                  :key="button.name"
                  :class="{
                    'button-active': button.active,
                    'button-unactive': !button.active,
                  }"
                  @click.prevent="setActiveButton(button)"
                  class="mr-2"
                  type="button"
                >
                  <span
                    :class="{
                      'text-active': button.active,
                      'text-unactive': !button.active,
                    }"
                    >{{ button.name }}</span
                  >
                </button>
              </div>

              <div v-for="(orderGroup, index) in orders" :key="index">
                <div
                  v-for="(order, orderIndex) in orderGroup.order"
                  :key="orderIndex"
                >
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Semua' ||
                      (order.order_status_id !== 0 &&
                        order.order_status_id !== 7 &&
                        order.order_status_id !== 15 &&
                        order.order_status_id !== 3 &&
                        order.order_status_id !== 5 &&
                        order.order_status_id !== 6)
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>

                          <!-- <p>{{ order.order_status_id }}</p> -->

                          <!-- Dipesan -->
                          <button
                            v-if="order.order_status_id === 0"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dipesan </span>
                          </button>
                          <!-- Diproses -->
                          <button
                            v-if="order.order_status_id === 15"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Diproses </span>
                          </button>

                          <!-- Dikirim -->
                          <button
                            v-if="order.order_status_id === 3"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dikirim </span>
                          </button>

                          <!-- Selesai -->
                          <button
                            v-if="order.order_status_id === 5"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #3d8a29;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Selesai </span>
                          </button>

                          <!-- Dibatalkan -->
                          <button
                            v-if="order.order_status_id === 7"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #e91313;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dibatalkan </span>
                          </button>

                          <!-- Pengembalian -->
                          <button
                            v-if="order.order_status_id === 6"
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #dda62d;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Pengembalian </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <!-- Cek apakah nama toko pada produk saat ini sama dengan nama toko pada produk sebelumnya -->
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <!-- Tampilkan detail produk -->
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk diPesan -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Dipesan' &&
                      order.order_status_id === 0
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dipesan </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk diproses -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Diproses' &&
                      order.order_status_id === 15
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Diproses </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk dikirim -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Dikirim' &&
                      order.order_status_id === 3
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #244786;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dikirim </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk Selesai -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Selesai' &&
                      order.order_status_id === 5
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #3d8a29;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Selesai </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk dibatalkan -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Dibatalkan' &&
                      order.order_status_id === 7
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #e91313;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Dibatalkan </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Produk Retur  -->
                  <div
                    class="mt-3"
                    v-if="
                      selectedButton === 'Pengembalian' &&
                      order.order_status_id === 6
                    "
                  >
                    <div class="card-notif">
                      <div class="d-flex">
                        <div class="p-2">
                          <span
                            style="
                              color: #000;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            No. Invoice {{ order.invoice_no }}
                          </span>
                          <!-- <p>{{ order.order_status_id }}</p> -->
                          <button
                            style="
                              border-radius: 5px;
                              border: 1px solid;
                              display: flex;
                              padding: 5px;
                              color: white;
                              background-color: #dda62d;
                              align-items: flex-start;
                              gap: 10px;
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 13.2px;
                              letter-spacing: -0.12px;
                            "
                          >
                            <span> Pengembalian </span>
                          </button>
                        </div>

                        <div class="ml-auto p-2">
                          <span
                            class="mr-3"
                            style="
                              color: #000;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 15.4px;
                              letter-spacing: -0.14px;
                            "
                          >
                            {{ formatDate(order.date_added) }}

                            <!-- Assuming you have a date property in your order data -->
                          </span>
                          <button
                            @click="handleDetailOrdersClick(order.order_id)"
                            style="
                              padding: 10px;
                              justify-content: center;
                              align-items: center;
                              gap: 10px;
                              align-self: stretch;
                              border-radius: 50px;
                              background: var(--danger-01, #e91313);
                            "
                          >
                            <span
                              style="
                                color: var(--danger-03, #ffd7d7);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px; /* 112.5% */
                                letter-spacing: -0.16px;
                              "
                              >Detail Orders
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-for="(product, productIndex) in order.products"
                        :key="productIndex"
                      >
                        <template
                          v-if="
                            productIndex === 0 ||
                            product.mall_name !==
                              order.products[productIndex - 1].mall_name
                          "
                        >
                          <div class="d-flex">
                            <div class="p-2">
                              <span
                                style="
                                  color: var(--primary-06, #143164);
                                  font-size: 16px;
                                  font-style: normal;
                                  font-weight: 600;
                                  line-height: 18px;
                                  letter-spacing: -0.16px;
                                "
                              >
                                {{ product.mall_name }}
                              </span>
                            </div>
                          </div>
                        </template>
                        <hr />
                        <div class="d-flex">
                          <div class="p-2">
                            <img
                              :src="
                                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                                product.image
                              "
                              style="width: 100px; height: 100px"
                            />
                          </div>
                          <div class="p-2">
                            <span class="text-produk">{{
                              product.product_name
                            }}</span>
                            <p class="ket-produk mt-1">
                              {{ product.description }}
                            </p>
                            <button
                              style="
                                border-radius: 5px;
                                border: 1px solid var(--primary-02, #b3ceff);
                                background: var(--primary-01, #d2e2ff);
                                display: flex;
                                padding: 5px;
                                align-items: flex-start;
                                gap: 10px;
                              "
                            >
                              <span
                                style="
                                  color: var(--primary-04, #3860a8);
                                  font-size: 12px;
                                  font-style: normal;
                                  font-weight: 400;
                                  line-height: 13.2px;
                                  letter-spacing: -0.12px;
                                "
                              >
                                7 Hari Pengembalian
                              </span>
                            </button>
                            <p
                              style="padding-left: 5px"
                              class="harga-history mt-2"
                            >
                              {{ product.quantity_order }}x
                              {{
                                formatPrice(
                                  product.price -
                                    (product.price * product.diskon) / 100
                                )
                              }}
                            </p>
                          </div>
                          <div class="ml-auto p-2">
                            <div class="d-flex bd-highlight mb-3">
                              <div class="ml-auto bd-highlight">
                                <p
                                  style="
                                    padding-left: 5px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: #5178be;
                                  "
                                  class="harga-history mt-2"
                                >
                                  {{
                                    formatPrice(
                                      product.price * product.quantity_order -
                                        (product.diskon / 100) *
                                          (product.price *
                                            product.quantity_order)
                                    )
                                  }}
                                </p>

                                <!-- <span
                                  style="
                                    color: var(--primary-05, #244786);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 18px;
                                    letter-spacing: -0.16px;
                                  "
                                >
                                  Total
                                  {{ formatPrice(product.price) }}
                                </span> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="mr-4"
                        >
                          Subtotal Produk
                        </div>
                        <div></div>
                        <div
                          style="
                            color: var(--primary-05, #244786);
                            font-size: 16px;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -0.16px;
                          "
                          class="ml-3"
                        >
                          <b>{{ formatPrice(order.subtotal) }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<script>
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "historyPesananPage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      loading: false,
      buttons: [
        { name: "Semua", active: true },
        { name: "Dipesan", active: false },
        { name: "Diproses", active: false },
        { name: "Dikirim", active: false },
        { name: "Selesai", active: false },
        { name: "Dibatalkan", active: false },
        { name: "Pengembalian", active: false },
      ],
      selectedButton: "Semua",
      orders: [],
    };
  },
  methods: {
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      const formattedDate = new Date(dateString).toLocaleString(
        "id-ID",
        options
      );
      return formattedDate;
    },
    // historyPesanan.vue
    async handleDetailOrdersClick(orderId) {
      try {
        this.loading = true;
        localStorage.setItem("selectedOrderId", orderId);
        console.log("Selected Order ID for localStorage:", orderId);

        await this.$router.push("/pesanan");
      } catch (error) {
        console.error("Error navigating to order details:", error);
      } finally {
        this.loading = false;
      }
    },

    formatPrice(price) {
      // Menggunakan metode ini untuk mengonversi harga ke format Rupiah (Rp) tanpa desimal
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(price);
    },
    setActiveButton(clickedButton) {
      this.selectedButton = clickedButton.name;
      this.buttons.forEach((button) => {
        button.active = button === clickedButton;
      });
    },
    async fetchOrders() {
      try {
        const token = localStorage.getItem("token");
        const idCustomer = localStorage.getItem("id_customer");

        const response = await fetch(
          `https://stagingapi.eurekabookhouse.com/order?customer_id=${idCustomer}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        if (response.ok) {
          // console.log("Orders retrieved successfully:", result);
          // Update your component data with the fetched orders
          this.orders = result.data;
          console.log("iniii data nya", this.orders);
        } else {
          console.error("Error retrieving orders:", result.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    },
  },
  mounted() {
    // Call the fetchOrders method when the component is mounted
    this.fetchOrders();
  },
};
</script>
<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255); /* Semi-transparent white background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a high z-index to ensure it's above other elements */
}
.title-notif {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.8px; /* 110% */
  letter-spacing: -0.28px;
}
.card-notif {
  border-radius: 10px;
  background: var(--neutral-01, #fefefe);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: auto;
  padding: 12px;
  gap: 12px;
}
.text-produk {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
}
.ket-produk {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.2px; /* 110% */
  letter-spacing: -0.12px;
}
.harga-history {
  color: var(--primary-05, #244786);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.jam-status {
  color: var(--primary-04, #3860a8);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.text-right {
  text-align: right;
}
.button-active {
  display: flex;
  height: 42px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  background: var(--primary-05, #244786);
}
.text-active {
  color: var(--neutral-01, #fefefe);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
.button-unactive {
  display: flex;
  height: 42px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid var(--neutral-03, #cbcbcb);
  background: var(--neutral-01, #fefefe);
}
.text-unactive {
  color: var(--primary-05, #244786);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  letter-spacing: -0.14px;
}
</style>
