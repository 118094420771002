<template>
  <section class="bg6 p-t-15 p-b-10">
    <form id="form-cart" class="p-b-85">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-xl-3 m-lr-auto d-none d-lg-block">
            <sidebarPage />
          </div>

          <div class="col-lg-2 col-xl-3 m-lr-auto m-b-50 mt-5">
            <div
              class="bg0 bor10 p-all-15 m-b-10 p-lr-15-sm"
              style="border-radius: 15px"
            >
              <span
                class="text-left"
                style="
                  color: var(--primary-05, #244786);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px; /* 112.5% */
                  letter-spacing: -0.16px;
                "
                >Foto Profil</span
              >
              <div class="mt-2 ava-preview flex-c-m">
                <img
                  class="img-thumbnail"
                  style="border-radius: 15px; height: 335px"
                  :src="
                    customer.avatar
                      ? 'https://stagingapi.eurekabookhouse.com/' +
                        customer.avatar
                      : 'https://www.eurekabookhouse.co.id/assets/uplod/default-avatar.png'
                  "
                />
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-xl-6 m-lr-auto m-b-50 mt-5">
            <div
              class="bg0 bor10 p-all-15 m-b-15 p-lr-15-sm"
              style="border-radius: 15px; max-height: 100vh; overflow: auto"
            >
              <div style="padding: 3%">
                <div class="profile">
                  <div>
                    <span class="txt-title-profile">Biodata Diri</span>
                  </div>
                  <div>
                    <router-link :to="'/Users/editProfile'">
                      <button class="btn-edit">
                        <span class="txt-edit">Edit</span>
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
              <hr />
              <div class="bg-profile">
                <div class="profile">
                  <div>
                    <p class="text-nama text-left">Nama Depan</p>
                    <p class="isi-nama text-left">{{ customer.firstname }}</p>
                  </div>
                </div>
                <div class="profile">
                  <div>
                    <p class="text-nama text-left">Nama Belakang</p>
                    <p class="isi-nama text-left">{{ customer.lastname }}</p>
                  </div>
                </div>
                <div class="profile">
                  <div>
                    <p class="text-nama text-left">E-mail</p>
                    <p class="isi-nama text-left">{{ customer.email }}</p>
                  </div>
                </div>
                <div class="profile">
                  <div>
                    <p class="text-nama text-left">Nomor Telepon</p>
                    <p class="isi-nama text-left">{{ customer.telephone }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
import sidebarPage from "@/components/Users/sidebar.vue";
export default {
  name: "ProfilePage",
  components: {
    sidebarPage,
  },
  data() {
    return {
      customer: null,
    };
  },
  created() {
    this.fetchCustomerDetails(); // Call the method to fetch customer details
  },
  methods: {
    fetchCustomerDetails() {
      const idCustomer = localStorage.getItem("id_customer");
      const token = localStorage.getItem("token");
      const apiUrl = `https://stagingapi.eurekabookhouse.com/customer/detail?customer_id=${idCustomer}`;

      fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            // Save customer details to local storage
            localStorage.setItem(
              "customer_details",
              JSON.stringify(data.data.firstname)
            );

            // Set the customer data in the component
            this.customer = data.data;
          } else {
            Swal.fire({
              confirmButtonColor: "#3860A8",
              title: "Error",
              text: "Failed to fetch customer details",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching customer details: ", error);
        });
    },
  },
};
</script>
