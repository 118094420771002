<template>
  <div class="dropdown">
    <div
      class="dropdown-item"
      @mouseenter="showSubmenu1 = true"
      @mouseleave="showSubmenu1 = false"
      style="color: white"
    >
      <b-icon icon="list" aria-hidden="true" class="right-icon"></b-icon>
      Kategori
      <b-icon
        icon="chevron-down"
        aria-hidden="true"
        class="right-icon"
      ></b-icon>
      <div class="submenu" v-show="showSubmenu1">
        <div
          class="submenu-item"
          v-for="item in submenuItems1"
          :key="item.category_id"
          @mouseenter="updateSubmenu2(item)"
          @mouseleave="clearSubmenu2"
        >
          <router-link :to="'/kategori/' + item.slug">
            <div class="text d-flex" @click="clearSubmenu3">
              <div class="bd-highlight mr-2">
                <img
                  :src="
                    item.image
                      ? `https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/${item.image}`
                      : 'https://stagingapi.eurekabookhouse.com/assets/front/images/icons/nerd/010-book.svg'
                  "
                  style="width: 20px"
                  alt="Icon"
                />
              </div>
              <div class="bd-highlight">
                <span>{{ item.name }}</span>
              </div>
              <!-- Tambahkan kondisi untuk menampilkan ikon chevron -->
              <div class="ml-auto bd-highlight">
                <b-icon
                  v-if="item.subcategories && item.subcategories.length > 0"
                  icon="chevron-right"
                  aria-hidden="true"
                ></b-icon>
              </div>
            </div>
          </router-link>

          <div
            class="submenu submenu-right"
            v-show="showSubmenu2 && currentHoveredItem === item"
          >
            <div
              class="submenu-item"
              v-for="subItem in submenuItems2"
              :key="subItem.category_id"
              @mouseenter="updateSubmenu3(subItem)"
              @mouseleave="clearSubmenu3"
            >
              <router-link :to="'/kategori/' + subItem.slug">
                <div class="submenu-content text">
                  <span>{{ subItem.name }}</span>
                  <!-- Tambahkan kondisi untuk menentukan apakah menampilkan ikon chevron -->
                  <b-icon
                    v-if="
                      subItem.subcategories && subItem.subcategories.length > 0
                    "
                    icon="chevron-right"
                    aria-hidden="true"
                  ></b-icon>
                </div>
              </router-link>

              <div
                class="submenu submenu-right"
                v-show="showSubmenu3 && currentHoveredSubItem === subItem"
              >
                <div
                  v-for="subSubItem in submenuItems3"
                  :key="subSubItem.category_id"
                  @mouseenter="currentHoveredSubSubItem = subSubItem"
                  @mouseleave="currentHoveredSubSubItem = null"
                >
                  <router-link :to="'/kategori/' + subSubItem.slug">
                    <div class="submenu-item text">
                      <span>{{ subSubItem.name }}</span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const showSubmenu1 = ref(false);
const showSubmenu2 = ref(false);
const showSubmenu3 = ref(false);

const submenuItems1 = ref([]);
const submenuItems2 = ref([]);
const submenuItems3 = ref([]);

const currentHoveredItem = ref(null);
const currentHoveredSubItem = ref(null);
const currentHoveredSubSubItem = ref(null); // Variabel untuk submenu ketiga

// Fungsi untuk membersihkan submenu ketiga
const clearSubmenu3 = () => {
  showSubmenu3.value = false;
};

// Fungsi untuk memperbarui submenu kedua
const updateSubmenu2 = (item) => {
  showSubmenu2.value = true;
  currentHoveredItem.value = item;
  currentHoveredSubItem.value = null;
  currentHoveredSubSubItem.value = null;

  // Set submenuItems2 berdasarkan item yang di-hover
  submenuItems2.value = item.subcategories.map((subItem) => ({
    category_id: subItem.category_id,
    name: subItem.name,
    slug: subItem.slug,
    subcategories: subItem.subcategories || [],
  }));
};

// Fungsi untuk memperbarui submenu ketiga
const updateSubmenu3 = (subItem) => {
  showSubmenu3.value = true;
  currentHoveredSubItem.value = subItem;
  currentHoveredSubSubItem.value = null;

  // Set submenuItems3 berdasarkan subItem yang di-hover
  submenuItems3.value = subItem.subcategories.map((subSubItem) => ({
    category_id: subSubItem.category_id,
    name: subSubItem.name,
    slug: subSubItem.slug,
  }));
};

// Fungsi untuk membersihkan submenu kedua
const clearSubmenu2 = () => {
  showSubmenu2.value = false;
  currentHoveredItem.value = null;
  showSubmenu3.value = false;
  currentHoveredSubItem.value = null;
  currentHoveredSubSubItem.value = null;
};

onMounted(async () => {
  try {
    const response = await axios.get(
      "https://stagingapi.eurekabookhouse.com/category/sub"
    );
    const data = response.data.data.order;

    // Mengatur submenuItems1 berdasarkan data yang diterima
    submenuItems1.value = data.map((item) => ({
      category_id: item.category_id,
      name: item.name,
      slug: item.slug,
      image: item.image,
      subcategories: item.subcategories || [],
    }));
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});
</script>

<style scoped>
.submenu-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submenu-content span {
  margin-right: 10px; /* Sesuaikan sesuai kebutuhan untuk jarak antara teks dan ikon */
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-item {
  padding: 5%;
  cursor: pointer;
  background-color: transparent;
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  z-index: 1000;
  background-color: white;
  /* Menambahkan transisi untuk tampilan yang lebih halus */
  transition: opacity 0.2s ease, visibility 0.2s ease;
  opacity: 0;
  visibility: hidden;
  height: 940px;
}

.submenu-right {
  left: 100%;
  top: 0;
}

.submenu-item {
  padding: 10px;
  cursor: pointer;
  text-align: left;
}

.submenu-item:hover,
.dropdown-item:hover .submenu .submenu-right .submenu-item:hover {
  /* Gaya hover */
}

.dropdown-item:hover .submenu {
  display: block;
  opacity: 1;
  visibility: visible;
}

.text {
  color: black;
}

.text:hover {
  color: #2c497e;
  font-size: 16px;
  font-weight: 800;
}

</style>
